import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, capitalize, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import ForwardIcon from '@material-ui/icons/Forward';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PageTitle from '../page-title/PageTitle';
import {
  CalendarEventType,
  DocumentTemplateType,
  FileNode,
  IConformingCenterProjectSummary,
  IFile,
  S3UploadResponse,
  SecurityPermissionLevel,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import DoughnutData, { DoughnutDataItem } from '../dashboard/DoughnutData';
import { getTemplates } from '../../models/api/templates';
import LogCard from './LogCard';
import DocumentIndex from '../document-index/DocumentIndex';
import NavAppbar from '../nav-top/NavAppbar';
import {
  getAllProjectUsersState,
  getDisableProcoreIntegrationFeatures,
  getPhysicalLocationsState,
  getProjectState,
} from '../../features/project/selectors';
import {
  documentIsOverdue,
  documentTypeLookup,
  fileIsPdf,
  formatDate,
  IndexMatchParams,
  openInNewTab,
  parseDate,
  showDocumentDisplay,
  urlToDocumentType,
} from '../../scripts/utils';
import {
  getDocumentsState,
  getSelectedDivision,
  getSelectedSection,
} from '../../features/documents/selectors';
import {
  fetchDocumentsByType,
  reloadDocuments,
  setSelectedSection,
} from '../../features/documents/actions';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import getHeaders from '../document-index/DocumentLogHeaders';
import { fetchProject, reloadProject } from '../../features/project/actions';
import DocumentDisplay, { SECONDARY_NAVIGATION_WIDTH } from '../document-display/DocumentDisplay';
import { getUserState } from '../../features/user/selectors';
import EyeIcon from '@material-ui/icons/Visibility';
import { setBid } from '../../features/bid/actions';
import { setDocument } from '../../features/document/actions';
import PlanholderLog from './PlanholderLog';
import PrimeBiddersList from './PrimeBiddersList';
import { addSnackbar } from '../../features/snackbar/actions';
import CircularLoader from '../loader/CircularLoader';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import { ParsingOptions, read, Sheet2JSONOpts, utils } from 'xlsx';
import {
  insertMultipleProjectCalendarEvents,
  ProjectCalendarEvent,
} from '../../models/api/calendar';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddDocumentDialog from '../dialogs/AddDocumentDialog';
import { getDocPermission } from '../../scripts/store-utils';
import { Add, Map } from '@material-ui/icons';
import {
  editFileById,
  importBidTabulationsFileFromDesign,
  uploadBidTabulationsFile,
} from '../../models/api/filesystem';
import { patchProject } from '../../models/api/project';
import {
  PRIMARY_NAVIGATION_WIDTH_CLOSED,
  PRIMARY_NAVIGATION_WIDTH_OPEN,
} from '../drawer/NavDrawer';
import { generatePlaceholders, getConformingCenterSummaryByProjectId } from '../../models/api/dcc';
import { getSummaryObjectByType, PublishType } from '../document-conforming-center/DCCUtils';
import SubmittalPlaceholderQuestionDialog from '../document-conforming-center/SubmittalPlaceholderQuestionDialog';
import DashboardChanges from '../dashboard/DashboardChanges';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import PublishingCenterDrawings from '../document-conforming-center/PublishingCenterDrawings';
import PublishingCenterSpecifications from '../document-conforming-center/PublishingCenterSpecifications';
import ViewByFloorplanDialog from '../punch-lists/ViewByFloorplanDialog';
import { FilterDraftOption } from '../dialogs/DocumentLogFilterDialog';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    flexGrow: 1,
    padding: theme.spacing(3),
    '&MuiPaper-outlined': {
      border: 0,
    },
    overflowX: 'auto',
  },
  headerCards: {
    paddingBottom: 16,
  },
  paper: {
    height: '100%',
    color: theme.palette.text.secondary,
    boxShadow: '0px 5px 15px rgb(0, 0, 0, .15)',
  },
  progressBar: {
    height: '13px',
    width: '85%',
    borderRadius: '20px',
    background: '#C4C4C4',
    marginLeft: theme.spacing(1),
  },
  progressFiller: {
    height: '100%',
    background: '#2BB073',
    borderRadius: 'inherit',
    transition: 'width .2s ease-in',
  },
  completedPercentage: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 21,
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    color: '#464546',
  },
  completedSubmittalsText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    color: '#464546',
  },
  assignedToMeText: {
    marginLeft: '5px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    textTransform: 'uppercase',
    color: '#7A797A', // Gray 500
  },
  reviewComplete: {
    padding: theme.spacing(3),
  },
  Cards: {
    zIndex: 0,
  },
}));

type Props = {
  drawerOpen: boolean;
  setIndexDrawerOpen: (input: boolean) => void;
  indexDrawerOpen: boolean;
};

const showLogCards = false;

export default function DocumentLogPage(props: Props) {
  const classes = useStyles();
  const { drawerOpen, setIndexDrawerOpen, indexDrawerOpen } = props;
  const theme = useTheme();
  const params = useParams<IndexMatchParams>();
  const dispatch = useDispatch();
  const docType = urlToDocumentType[params.type] as DocumentTemplateType;
  const selectedProject = useSelector(getProjectState);
  const selectedSection = useSelector(getSelectedSection);
  const selectedDivision = useSelector(getSelectedDivision);
  const documents = useSelector(getDocumentsState);
  const user = useSelector(getUserState);
  const projectUsers = useSelector(getAllProjectUsersState);
  const permission = getDocPermission();
  const history = useHistory();
  const disableProcoreIntegrationFeatures = useSelector(getDisableProcoreIntegrationFeatures);
  const locations = useSelector(getPhysicalLocationsState);

  const isPlanholder = docType === DocumentTemplateType.PlanholderList;

  const [isDownloading, setIsDownloading] = useState(false);
  const [placeholdersLoading, setPlaceholdersLoading] = useState(false);
  const [drawingsPublishingLoading, setDrawingsPublishingLoading] = useState(false);
  const [specsPublishingLoading, setSpecsPublishingLoading] = useState(false);

  const [filterFromDate, setFilterFromDate] = useState<Dayjs | null>(null);
  const [filterToDate, setFilterToDate] = useState<Dayjs | null>(null);
  const [filterStatus, setFilterStatus] = useState<string[] | undefined>(undefined);
  const [filterAssignTo, setFilterAssignTo] = useState<string | undefined>(undefined);
  const [filterDownload, setFilterDownload] = useState(false);
  const [filterView, setFilterView] = useState(false);
  const [filterProcore, setFilterProcore] = useState(false);
  const [filterPunchListStatus, setFilterPunchListStatus] = useState<string[]>();
  const [filterFromCost, setFilterFromCost] = useState<number>();
  const [filterToCost, setFilterToCost] = useState<number>();
  const [filterBuilding, setFilterBuilding] = useState('');
  const [filterFloor, setFilterFloor] = useState<string[]>([]);
  const [filterSourceFile, setFilterSourceFile] = useState('');
  const [filterPackage, setFilterPackage] = useState('');
  const [filterDraft, setFilterDraft] = useState(FilterDraftOption.ALL);
  const [filterSubcontractor, setFilterSubcontractor] = useState('');

  const [searchInput, setSearchInput] = useState<string>('');

  const security = useSelector(getCurrentSecurityGroup);
  const [templateIds, setTemplateIds] = useState<Dictionary<string>>();
  const [punchListSetupDisabled, setPunchListSetupDisabled] = useState(true);

  const [scheduleUploadDialogOpen, setScheduleUploadDialogOpen] = useState(false);
  const [inputSchedule, setInputSchedule] = useState<File>();

  const [uploadWorkflowOpen, setUploadWorkflowOpen] = useState(false);
  const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState(false);

  const [submittalsDialogOpen, setSubmittalsDialogOpen] = useState(false);
  const [summary, setSummary] = useState<IConformingCenterProjectSummary>();

  const [floorplanDialogOpen, setFloorplanDialogOpen] = useState(false);

  useEffect(() => {
    setUploadWorkflowOpen(false);
  }, [docType]);

  const [bidTabulationDialogOpen, setBidTabulationDialogOpen] = useState(false);
  const [bidTabulationFile, setBidTabulationFile] = useState<File | FileNode>();
  const [uploadProgress, setUploadProgress] = useState(0);

  const mainRef = useRef<HTMLBodyElement>(null);
  const documentIndexRef = useRef<HTMLDivElement>(null);
  const [overlayHeight, setOverlayHeight] = useState(0);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(
      docType === DocumentTemplateType.Submittals ||
        docType === DocumentTemplateType.CloseoutSubmittals ||
        docType === DocumentTemplateType.Specifications,
    );
  }, [documents, docType]);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (documentIndexRef.current)
      setOverlayHeight(documentIndexRef.current.getBoundingClientRect().top);
  }, [documentIndexRef.current?.clientHeight]);

  const fetchSummary = async (projectId: string) => {
    const s = await getConformingCenterSummaryByProjectId(projectId);
    setSummary(s);
    return s;
  };

  useEffect(() => {
    if (
      selectedProject &&
      (user.isSiteAdmin ||
        user.adminOfSubscriberId === selectedProject.subscriberId ||
        (security &&
          security.publishingCenterPermission &&
          security.publishingCenterPermission > 0))
    ) {
      fetchSummary(selectedProject.id);
    }
  }, [user, security, selectedProject]);

  // types inside the IndexHeader file doesn't have all these doc types like in here.
  // this is why we have to extract the type from here and pass it along as a prop
  // note: these columnTitles are not final, change as needed.

  const awaitingSubmissionItems: DoughnutDataItem[] = [];
  const submittedForReviewItems: DoughnutDataItem[] = [];
  const underReviewItems: DoughnutDataItem[] = [];
  const reviewCompleteItems: DoughnutDataItem[] = [];
  const rAndRItems: DoughnutDataItem[] = [];
  const overdueItems: DoughnutDataItem[] = [];
  const upcomingItems: DoughnutDataItem[] = [];
  const assignedToCurrentUserItems: { item: DoughnutDataItem; type: string }[] = [];

  let numOfSubmittals = 0;

  // potential logic for overdue and upcoming. flawed because assumes that a
  // document goes straight from submitted to under review
  // currently no other way to get overdue that I can see, as there is no way to tell
  // how long a submittal has held a certain workflowStatus
  //
  // if submittal.reviewComplete === 'true' then approvedCount++
  // else if today - submittal.dateSubmitted > selectedProject.documentReviewPeriod then overdueItems.push(submittal)
  // else if today - submittal.dateSubmitted <= 3 && today - submittal.dateSubmitted > 0 then upcoming

  // console.log(submittal.dateSubmitted)
  // console.log(submittal.actualSubmissionDate)

  useEffect(() => {
    dispatch(setBid(null));
    dispatch(setDocument(null));
  }, []);

  documents.forEach((submittal) => {
    const item: DoughnutDataItem = {
      id: submittal.id,
      section: submittal.submittalSection || '',
      name: submittal.title?.substr(0, 40) || submittal.id || '',
    };
    if (
      searchInput !== '' &&
      ![submittal.title, submittal.submittalSection, submittal.id]
        .map((attr) => attr && attr.toLowerCase())
        .some((attr) => attr && attr.includes(searchInput.toLowerCase()))
    ) {
      return;
    }
    if (
      filterToDate &&
      filterFromDate &&
      (!submittal.dueDate ||
        parseDate(submittal.dueDate).isBefore(filterFromDate) ||
        parseDate(submittal.dueDate).isAfter(filterToDate))
    ) {
      return;
    }
    if (
      selectedSection &&
      (!submittal.submittalSectionDescription ||
        !selectedSection!.title.includes(submittal.submittalSectionDescription))
    ) {
      return;
    }
    numOfSubmittals += 1;
    if (
      documentIsOverdue(
        submittal,
        docType === DocumentTemplateType.Submittals ||
          docType === DocumentTemplateType.CloseoutSubmittals,
      )
    ) {
      overdueItems.push(item);
      if (submittal.assignedToUserId && submittal.assignedToUserId === user.id) {
        assignedToCurrentUserItems.push({ item, type: 'overdue' });
      }
    } else {
      switch (submittal.workflowStatus) {
        case WorkflowStatusType.Initial:
          if ((submittal.revisionNumber || 0) > 0) {
            rAndRItems.push(item);
          } else {
            awaitingSubmissionItems.push(item);
            if (submittal.assignedToUserId && submittal.assignedToUserId === user.id) {
              assignedToCurrentUserItems.push({ item, type: 'initial' });
            }
          }
          break;
        case WorkflowStatusType.SubcontractorUploaded:
        case WorkflowStatusType.ReadyForSubmissionToArchitect:
        case WorkflowStatusType.GeneralContractorUploaded:
          awaitingSubmissionItems.push(item);
          if (submittal.assignedToUserId && submittal.assignedToUserId === user.id) {
            assignedToCurrentUserItems.push({ item, type: 'initial' });
          }
          break;
        case WorkflowStatusType.SubmittedForReview:
          submittedForReviewItems.push(item);
          if (submittal.assignedToUserId && submittal.assignedToUserId === user.id) {
            assignedToCurrentUserItems.push({ item, type: 'submitted_for_review' });
          }
          break;
        case WorkflowStatusType.UnderReview:
        case WorkflowStatusType.ArchitectUploaded:
          underReviewItems.push(item);
          if (submittal.assignedToUserId && submittal.assignedToUserId === user.id) {
            assignedToCurrentUserItems.push({ item, type: 'under_review' });
          }
          break;
        case WorkflowStatusType.Completed:
          reviewCompleteItems.push(item);
          if (submittal.assignedToUserId && submittal.assignedToUserId === user.id) {
            assignedToCurrentUserItems.push({ item, type: 'completed' });
          }
          break;
        default:
          upcomingItems.push(item);
          if (submittal.assignedToUserId && submittal.assignedToUserId === user.id) {
            assignedToCurrentUserItems.push({ item, type: 'due_next' });
          }
          break;
      }
    }
  });

  const approvedSubmittals = reviewCompleteItems.length;
  const totalCompletedSubmittals = approvedSubmittals / numOfSubmittals;
  const completedSubmittalsTotalPercentage = totalCompletedSubmittals * 100;

  const getAssignedData = () => {
    let data: DoughnutDataItem[] = [];
    assignedToCurrentUserItems.map((obj) => {
      if (
        filterStatus === undefined ||
        filterStatus.find((item) => item !== obj.type) !== undefined
      ) {
        data.push(obj.item);
      }
    });
    return data;
  };

  const userHasPermission = (subtitle: string) => {
    if (user.isSiteAdmin || user.adminOfSubscriberId) return true;
    if (templateIds) {
      const docType = subtitle in documentTypeLookup ? documentTypeLookup[subtitle] : subtitle;
      const docPermission = security?.securityGroupDocumentTemplateList!.find(
        (group) => group.documentTemplateId === templateIds[docType],
      )?.securityPermissionLevel;
      return !!(security && docPermission !== null && docPermission !== undefined);
    }
    return false;
  };

  useEffect(() => {
    if (!selectedProject) dispatch(fetchProject(params.projectId));
    else dispatch(fetchDocumentsByType(selectedProject.id, docType as DocumentTemplateType));
    dispatch(setSelectedSection(null));
  }, [selectedProject, docType]);

  useEffect(() => {
    getTemplates().then((result) => setTemplateIds(result));
  }, [selectedProject]);

  useEffect(() => {
    if (docType === DocumentTemplateType.PunchList) {
      if (permission && permission >= SecurityPermissionLevel.NUMBER_3)
        setPunchListSetupDisabled(false);
      else setPunchListSetupDisabled(true);
    }
  }, [docType, permission]);

  const handleImportSchedule = async () => {
    setScheduleUploadDialogOpen(false);
    if (!inputSchedule || !selectedProject) return;
    const hasDate = (obj: object) =>
      Object.values(obj).some((v) => Object.prototype.toString.call(v) === '[object Date]');

    type ScheduleEvent = { name: string; duration?: number; endTimestamp: string };

    const { sheet_to_json } = utils;

    const readOptions: ParsingOptions = {
      type: 'array',
      sheetStubs: true,
      cellStyles: true,
      cellHTML: true,
      cellText: true,
      cellFormula: true,
      cellDates: true,
    };

    const jsonOptions: Sheet2JSONOpts = {
      header: 'A',
    };

    const workbook = read(new Uint8Array(await inputSchedule.arrayBuffer()), readOptions);
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const rows = sheet_to_json<object>(sheet, jsonOptions).filter(hasDate);
    const results = rows
      .map((row) => {
        const values = Object.values(row);
        if (typeof values[0] === 'string')
          return {
            name: values[0],
            duration: values.find((v) => typeof v === 'number'),
            endTimestamp: (values[1] as Date).toLocaleString(),
          };
        else return undefined;
      })
      .filter((row) => row) as ScheduleEvent[];
    const newCalendarEvents = results.map((result) => {
      const endTimestamp = parseDate(result.endTimestamp).toISOString();
      const startTimestamp = result.duration
        ? parseDate(result.endTimestamp).subtract(result.duration, 'd').toISOString()
        : endTimestamp;
      return {
        name: result.name,
        type: CalendarEventType.Event,
        startTimestamp,
        endTimestamp,
      } as ProjectCalendarEvent;
    });
    try {
      await insertMultipleProjectCalendarEvents(selectedProject.id, newCalendarEvents);
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Schedule imported into calendar successfully.',
          severity: 'success',
        }),
      );
    } catch {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'There was an issue importing your schedule.',
          severity: 'error',
        }),
      );
    }
    console.table(results);
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleUploadBidTabulation = async () => {
    let response: S3UploadResponse;

    if ((bidTabulationFile as FileNode).relativeKey !== undefined) {
      response = await importBidTabulationsFileFromDesign(selectedProject!.id, {
        ownerId: (bidTabulationFile as FileNode).ownerId,
        fullKey: (bidTabulationFile as FileNode).fullKey,
        fullFileName: (bidTabulationFile as FileNode).relativeKey!,
        projectId: (bidTabulationFile as FileNode).projectId,
      });
    } else {
      response = await uploadBidTabulationsFile(
        selectedProject!.id,
        {
          fullFileName: (bidTabulationFile as File).name,
          useMultiPartUpload: (bidTabulationFile as File).size > MULTI_PART_FILE_SIZE,
        },
        bidTabulationFile as File,
        handleUploadProgress,
      );
    }

    if (response?.file)
      await patchProject(selectedProject!.id, { bidTabulationsFileId: response.file.id });

    setBidTabulationDialogOpen(false);
    dispatch(reloadProject());
  };

  const canPublishCompletedBidTabulation = (tabulationsFile: IFile) => {
    return (
      !tabulationsFile.isPublic &&
      permission &&
      permission >= SecurityPermissionLevel.NUMBER_3 &&
      user?.companyId === tabulationsFile?.creatorUser?.companyId
    );
  };

  const handlePublishCompletedBidTabulation = async (fileId: string) => {
    try {
      await editFileById(fileId, { isPublic: true });
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Completed Bid Tabulations file published successfully!',
          severity: 'success',
        }),
      );
      dispatch(reloadProject());
    } catch {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Something went wrong trying to publish the Completed Bid Tabulations file',
          severity: 'error',
        }),
      );
    }
  };

  const canUploadTabulation = () => {
    return (
      selectedProject?.bidSetup &&
      parseDate(selectedProject.bidSetup.dueDate).isBefore(dayjs()) &&
      (user.isSiteAdmin ||
        user.adminOfSubscriberId === selectedProject?.subscriberId ||
        (security?.bidManagementPermission !== null &&
          security?.bidManagementPermission !== undefined))
    );
  };

  let buttonContent: JSX.Element | undefined;
  if (docType === DocumentTemplateType.Schedules) {
    buttonContent = (
      <Button
        variant="contained"
        color="primary"
        onClick={() => setScheduleUploadDialogOpen(true)}
        style={{ padding: '0px 20px' }}
      >
        Import Schedule to Project Calendar
      </Button>
    );
  } else if (docType === DocumentTemplateType.BidTabulation) {
    const completedBidTabulationFile = selectedProject?.bidTabulationsFile;
    buttonContent = (
      <div>
        {completedBidTabulationFile ? (
          <div style={{ display: 'flex', marginBottom: 16 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!fileIsPdf(completedBidTabulationFile)}
              startIcon={<EyeIcon />}
              onClick={() => {
                openInNewTab(completedBidTabulationFile.id);
              }}
              style={{ width: 120 }}
            >
              View
            </Button>
            {/*{canPublishCompletedBidTabulation(completedBidTabulationFile) && (*/}
            {/*  <Button*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    startIcon={<Edit />}*/}
            {/*    onClick={() => handlePublishCompletedBidTabulation(completedBidTabulationFile!.id)}*/}
            {/*    style={{ marginLeft: 8, width: 120 }}*/}
            {/*  >*/}
            {/*    Publish*/}
            {/*  </Button>*/}
            {/*)}*/}
            <Typography variant="h6" style={{ color: '#464546', marginLeft: 8 }}>
              {completedBidTabulationFile.name}
            </Typography>
          </div>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={!canUploadTabulation()}
            onClick={() => setBidTabulationDialogOpen(true)}
            startIcon={<Add />}
          >
            Upload Completed Bid Tabulation
          </Button>
        )}
      </div>
    );
  } else if (docType === DocumentTemplateType.ChangeOrders) {
    buttonContent = <DashboardChanges isDocumentLogTablePage />;
  }

  const getContentOverlay = () => {
    let leftOffset = drawerOpen ? PRIMARY_NAVIGATION_WIDTH_OPEN : PRIMARY_NAVIGATION_WIDTH_CLOSED;
    if (
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.CloseoutSubmittals
    )
      leftOffset += SECONDARY_NAVIGATION_WIDTH;

    if (
      docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.CloseoutSubmittals
    ) {
      if (!selectedDivision)
        return (
          <div
            style={{
              position: 'absolute',
              height: document.body.offsetHeight,
              width: window.innerWidth - leftOffset,
              left: leftOffset,
              backgroundColor: 'rgba(70,69,70,0.40)',
              transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: drawerOpen
                  ? theme.transitions.duration.enteringScreen
                  : theme.transitions.duration.leavingScreen,
              }),
              zIndex: 1200,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', top: 116 }}>
              <ArrowBackIcon htmlColor="#FFF" style={{ fontSize: 96 }} />
              <Typography style={{ fontSize: 36, lineHeight: '40px', color: '#FFF' }}>
                Select the <b>division</b> and/or <b>section</b> for this upload
              </Typography>
            </div>
            <Typography
              style={{
                position: 'relative',
                top: 100,
                left: 95,
                fontSize: 36,
                lineHeight: '40px',
                color: '#FFF',
              }}
            >
              Don't see your division?{' '}
              <span
                onClick={() => setAddDocumentDialogOpen(true)}
                style={{ color: '#FFF', fontWeight: 600, cursor: 'pointer' }}
              >
                Click here
              </span>
            </Typography>
          </div>
        );
      if (addDocumentDialogOpen)
        return (
          <div
            style={{
              position: 'absolute',
              height: document.body.offsetHeight,
              width: document.body.offsetWidth,
              left: leftOffset,
              backgroundColor: 'rgba(70,69,70,0.35)',
              zIndex: 1200,
            }}
          />
        );
    }

    return (
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: window.innerWidth - leftOffset,
          height: overlayHeight,
          top: 0,
          left: leftOffset,
          backgroundColor: 'rgba(70,69,70,0.65)',
          transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: drawerOpen
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
          }),
          zIndex: 1200,
        }}
      >
        <Typography
          style={{
            fontSize: 36,
            lineHeight: '40px',
            color: '#FFF',
            textAlign: 'center',
          }}
        >
          Choose the {docType !== DocumentTemplateType.AsBuilt ? 'Submittal' : 'Drawing'} you want
          to upload, or select multiple{' '}
          {!drawerOpen && (docType !== DocumentTemplateType.AsBuilt ? 'Submittals' : 'Drawings')} to
          build a package
        </Typography>
        <Typography style={{ fontSize: 28, color: '#FFF', marginTop: 48 }}>
          Correct {docType !== DocumentTemplateType.AsBuilt ? 'Submittal' : 'Drawing'} not listed?{' '}
          <span
            onClick={() => setAddDocumentDialogOpen(true)}
            style={{ color: '#FFF', fontWeight: 600, cursor: 'pointer' }}
          >
            Click here to add a new one
          </span>
        </Typography>
      </div>
    );
  };

  const getPageTitle = () => {
    if (docType === DocumentTemplateType.Testing) return `Testing Reports`;
    if (docType === DocumentTemplateType.BidTabulation) return 'Bid Submissions';
    if (docType === DocumentTemplateType.Task) return 'Task & Share';
    if (docType === DocumentTemplateType.DesignPackages) return 'Design Package Review';
    if (docType === DocumentTemplateType.AsBuilt) return 'As-Built Drawings';
    return `${capitalize(docType || '')}`;
  };

  const getPageSubtitle = () => {
    if (docType === DocumentTemplateType.BidTabulation && selectedProject?.bidSetup?.dueDate) {
      const dueDate = parseDate(selectedProject.bidSetup.dueDate).format('MM/DD/YYYY hh:mm A z');
      return `Due Date: ${dueDate}`;
    }

    return undefined;
  };

  const handleCreateSubmittals = async () => {
    if (summary?.submittalSummary?.beforeUnpublished?.numDocuments) {
      setSubmittalsDialogOpen(true);
    } else {
      history.push(`/main/projects/${selectedProject!.id}/pub-center/conform-submittals`);
    }
  };

  const handleCreateAsBuilts = async () => {
    if (!selectedProject || !summary) return;

    setPlaceholdersLoading(true);

    try {
      const documentIds =
        getSummaryObjectByType(summary, PublishType.Drawings)?.published.documentIds || [];

      const generatedPlaceholderDocumentIds = await generatePlaceholders(documentIds);

      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully generated ${generatedPlaceholderDocumentIds.length} placeholders!`,
          severity: 'success',
        }),
      );

      dispatch(reloadDocuments());
    } finally {
      setPlaceholdersLoading(false);
    }
  };

  const handleGenerateSubmittals = async (generateForFirstDivisions?: boolean) => {
    setSubmittalsDialogOpen(false);
    if (!selectedProject || !summary) return;

    const documentIds =
      getSummaryObjectByType(summary, PublishType.Specifications)?.published.documentIds || [];

    const generatedPlaceholderDocumentIds = await generatePlaceholders(documentIds, {
      ignoredSpecificationDivisions: !generateForFirstDivisions ? ['00', '01'] : [],
    });

    dispatch(
      addSnackbar({
        id: Date.now(),
        message: `Successfully generated ${generatedPlaceholderDocumentIds.length} placeholders!`,
        severity: 'success',
      }),
    );

    history.push(`/main/projects/${selectedProject!.id}/pub-center/conform-submittals`);
  };

  return (
    <>
      {showDocumentDisplay(params.type) && (
        <DocumentDisplay
          isUploadingSubmittal={uploadWorkflowOpen}
          setIsUploadingSubmittal={setUploadWorkflowOpen}
          visible={visible}
          setVisible={setVisible}
          setIndexDrawerOpen={setIndexDrawerOpen}
          indexDrawerOpen={indexDrawerOpen}
        />
      )}
      {uploadWorkflowOpen && getContentOverlay()}
      <main ref={mainRef} className={classes.content}>
        {docType !== DocumentTemplateType.PunchList ? (
          <NavAppbar />
        ) : !isMobile ? (
          <NavAppbar />
        ) : (
          <div style={{ paddingTop: 50 }} />
        )}
        {!userHasPermission(docType!) ? (
          <div className={classes.content}>
            <Typography>ERROR: You do not have permission to view this page. Sorry!</Typography>
          </div>
        ) : (
          <>
            <PageTitle
              title={getPageTitle()}
              subtitle={getPageSubtitle()}
              isDrawings={docType === DocumentTemplateType.Drawings}
            />
            {!selectedProject && <h2>Select a project to view its documents</h2>}
            {docType === DocumentTemplateType.Submittals &&
            !!summary?.numSubmittalsNotPublishedWithPublishedSpecification &&
            !disableProcoreIntegrationFeatures ? (
              <div>
                <Typography style={{ marginBottom: 8 }}>
                  We found {summary?.numSubmittalsNotPublishedWithPublishedSpecification} submittals
                  in your published specifications.
                </Typography>
                <Typography style={{ marginBottom: 8 }}>
                  Would you like to create submittal placeholders for these items?
                </Typography>
                <Typography style={{ marginBottom: 16 }}>
                  Customize submittal placeholders from the Submittals page at any time.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleCreateSubmittals}>
                  Review Submittal Placeholders Before Publishing
                </Button>
              </div>
            ) : null}
            {docType === DocumentTemplateType.AsBuilt &&
            !!summary?.numAsBuiltsBeforeUnpublishedWithPublishedDrawing ? (
              <div>
                <Typography style={{ marginBottom: 8 }}>
                  We found {summary?.numAsBuiltsBeforeUnpublishedWithPublishedDrawing} drawings in
                  your uploaded drawings document.
                </Typography>
                <Typography style={{ marginBottom: 8 }}>
                  Would you like to create As-Built placeholders for each one?
                </Typography>
                <Typography style={{ marginBottom: 16 }}>
                  Customize As-Built placeholders in the Close Out tab.
                </Typography>
                {!placeholdersLoading ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleCreateAsBuilts}
                  >
                    Create As-Built Placeholders
                  </Button>
                ) : (
                  <CircularLoader />
                )}
              </div>
            ) : null}
            {docType === DocumentTemplateType.Drawings && summary ? (
              <PublishingCenterDrawings
                setPageIsLoading={setDrawingsPublishingLoading}
                summary={summary}
                fetchSummary={() => fetchSummary(selectedProject!.id)}
                isDocumentLogPage
              />
            ) : null}
            {docType === DocumentTemplateType.Specifications && summary ? (
              <PublishingCenterSpecifications
                setPageIsLoading={setSpecsPublishingLoading}
                summary={summary}
                fetchSummary={() => fetchSummary(selectedProject!.id)}
                isDocumentLogPage
              />
            ) : null}
            {docType === DocumentTemplateType.PunchList && (
              <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300 }}>
                {!isMobile ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={punchListSetupDisabled}
                    onClick={() => {
                      history.push(`/main/projects/${params.projectId}/documents/punch-list/setup`);
                    }}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    PUNCH LIST SETUP & MANAGEMENT
                  </Button>
                ) : null}
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Map />}
                  disabled={locations.length === 0}
                  onClick={() => setFloorplanDialogOpen(true)}
                  style={{ marginTop: 12, marginBottom: isMobile ? 0 : -8 }}
                >
                  View By Floorplans
                </Button>
              </div>
            )}
            {!isDownloading ? (
              buttonContent
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CircularLoader style={{ justifyContent: 'flex-start' }} />
                {docType === DocumentTemplateType.RequestsForInformation && (
                  <Typography style={{ marginLeft: '8px' }}>This may take a while...</Typography>
                )}
              </div>
            )}

            <Grid container spacing={2} className={classes.headerCards} alignContent="stretch">
              {showLogCards &&
                (docType === DocumentTemplateType.Submittals ||
                  docType === DocumentTemplateType.CloseoutSubmittals) && (
                  <>
                    {getAssignedData().length > 0 &&
                      (filterAssignTo === undefined || filterAssignTo === user.id) && (
                        <Grid item xl={4} lg={6} md={12} sm={12} xs={12} className={classes.Cards}>
                          <Paper className={classes.paper} style={{ marginBottom: 24 }}>
                            <LogCard
                              title="Assigned To Me"
                              headerColor="#C9BD3A"
                              icon={<AccessibilityIcon />}
                            >
                              <DoughnutData
                                data={getAssignedData()}
                                numItems={getAssignedData().length}
                                setFilter={() => setFilterAssignTo(user.id)}
                                type={docType}
                              />
                            </LogCard>
                          </Paper>
                        </Grid>
                      )}

                    {(filterStatus?.find((item) => item === 'overdue') !== undefined ||
                      filterStatus?.find((item) => item === undefined) !== undefined) &&
                      overdueItems.length > 0 && (
                        <Grid item xl={4} lg={6} md={12} sm={12} xs={12} className={classes.Cards}>
                          <Paper className={classes.paper}>
                            <LogCard title="Overdue" headerColor="#ED3F26" icon={<WarningIcon />}>
                              <DoughnutData
                                data={overdueItems}
                                numItems={overdueItems.length}
                                setFilter={() => setFilterStatus([...filterStatus, 'overdue'])}
                                type={docType}
                              />
                            </LogCard>
                          </Paper>
                        </Grid>
                      )}
                    {/* <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <LogCard title="Revise & Resubmit" headerColor="#EA7F01" icon={<LoopIcon />}>
                  <DoughnutData data={rAndRItems} numItems={rAndRItems.length} />
                </LogCard>
              </Paper>
            </Grid> */}
                    {(filterStatus?.find((item) => item === 'due_next') !== undefined ||
                      filterStatus?.find((item) => item === undefined) !== undefined) &&
                      upcomingItems.length > 0 && (
                        <Grid item xl={4} lg={6} md={12} sm={12} xs={12} className={classes.Cards}>
                          <Paper className={classes.paper}>
                            <LogCard title="Next Due" headerColor="#874080" icon={<ForwardIcon />}>
                              <DoughnutData
                                data={upcomingItems}
                                numItems={upcomingItems.length}
                                setFilter={() => setFilterStatus([...filterStatus, 'due_next'])}
                                type={docType}
                              />
                            </LogCard>
                          </Paper>
                        </Grid>
                      )}
                    {(filterStatus?.find((item) => item === 'initial') !== undefined ||
                      filterStatus?.find((item) => item === undefined) !== undefined) &&
                      awaitingSubmissionItems.length > 0 && (
                        <Grid item xl={4} lg={6} md={12} sm={12} xs={12} className={classes.Cards}>
                          <Paper className={classes.paper}>
                            <LogCard
                              title="Awaiting Submission"
                              headerColor="#0947B9"
                              icon={<ForwardIcon />}
                            >
                              <DoughnutData
                                data={awaitingSubmissionItems}
                                numItems={awaitingSubmissionItems.length}
                                setFilter={() => setFilterStatus([...filterStatus, 'initial'])}
                                type={docType}
                              />
                            </LogCard>
                          </Paper>
                        </Grid>
                      )}
                    {(filterStatus?.find((item) => item === 'submitted_for_review') !== undefined ||
                      filterStatus?.find((item) => item === undefined) !== undefined) &&
                      submittedForReviewItems.length > 0 && (
                        <Grid item xl={4} lg={6} md={12} sm={12} xs={12} className={classes.Cards}>
                          <Paper className={classes.paper}>
                            <LogCard
                              title="Submitted for Review"
                              headerColor="#73A3FF"
                              icon={<ForwardIcon />}
                            >
                              <DoughnutData
                                data={submittedForReviewItems}
                                numItems={submittedForReviewItems.length}
                                setFilter={() =>
                                  setFilterStatus([...filterStatus, 'submitted_for_review'])
                                }
                                type={docType}
                              />
                            </LogCard>
                          </Paper>
                        </Grid>
                      )}
                    {(filterStatus?.find((item) => item === 'under_review') !== undefined ||
                      filterStatus?.find((item) => item === undefined) !== undefined) &&
                      underReviewItems.length > 0 && (
                        <Grid item xl={4} lg={6} md={12} sm={12} xs={12} className={classes.Cards}>
                          <Paper className={classes.paper}>
                            <LogCard
                              title="Under Review"
                              headerColor="#74D8D8"
                              icon={<ForwardIcon />}
                            >
                              <DoughnutData
                                data={underReviewItems}
                                numItems={underReviewItems.length}
                                setFilter={() => setFilterStatus([...filterStatus, 'under_review'])}
                                type={docType}
                              />
                            </LogCard>
                          </Paper>
                        </Grid>
                      )}
                    <Grid item xl={4} lg={6} md={12} sm={12} xs={12} className={classes.Cards}>
                      <Paper className={classes.paper}>
                        <LogCard
                          title="Review Complete"
                          headerColor="#128750"
                          icon={<CheckBoxOutlinedIcon />}
                        >
                          <div className={classes.reviewComplete}>
                            {/* <div>04 01 20 Maintenance of Concrete</div>
                  <div>04 01 20 Maintenance of Concrete</div> */}
                            <Typography className={classes.completedPercentage}>
                              {Number.isNaN(completedSubmittalsTotalPercentage)
                                ? '0%'
                                : `${Math.round(completedSubmittalsTotalPercentage)}%`}
                              <div className={classes.progressBar}>
                                <div
                                  className={classes.progressFiller}
                                  style={{
                                    width: `${
                                      totalCompletedSubmittals > 1
                                        ? 100
                                        : totalCompletedSubmittals * 100
                                    }%`,
                                    background:
                                      totalCompletedSubmittals <= 1 ? '#2BB073' : '#C4C4C4',
                                  }}
                                />
                              </div>{' '}
                            </Typography>
                            <Typography className={classes.completedSubmittalsText}>
                              {approvedSubmittals} of {numOfSubmittals} Submittals Complete Total
                            </Typography>
                          </div>
                          <DoughnutData
                            data={reviewCompleteItems}
                            numItems={reviewCompleteItems.length}
                            isReviewCompleteCard
                            setFilter={() =>
                              filterStatus !== undefined
                                ? setFilterStatus([...filterStatus, 'completed'])
                                : setFilterStatus(['completed'])
                            }
                            type={docType}
                          />
                        </LogCard>
                      </Paper>
                    </Grid>
                  </>
                )}
              {docType === DocumentTemplateType.WarrantyItems && (
                <>
                  <Grid
                    container
                    spacing={2}
                    className={classes.headerCards}
                    alignContent="stretch"
                  >
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                      <Paper className={classes.paper}>
                        <LogCard
                          title={`Substantial Completion Date - ${
                            selectedProject?.substantialCompletionDate
                              ? formatDate(selectedProject.substantialCompletionDate, true)
                              : 'N/A'
                          }`}
                          headerColor="#874080"
                          icon={<ForwardIcon />}
                          hideChildren
                        />
                      </Paper>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                      <Paper className={classes.paper}>
                        <LogCard
                          title={`Warranty Period Start Date - ${
                            selectedProject?.warrantyPeriodStartDate
                              ? formatDate(selectedProject.warrantyPeriodStartDate, true)
                              : 'N/A'
                          }`}
                          headerColor="#0947B9"
                          icon={<ForwardIcon />}
                          hideChildren
                        />
                      </Paper>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                      <Paper className={classes.paper}>
                        <LogCard
                          title={`Warranty Period End Date - ${
                            selectedProject?.warrantyPeriodEndDate
                              ? formatDate(selectedProject.warrantyPeriodEndDate, true)
                              : 'N/A'
                          }`}
                          headerColor="#73A3FF"
                          icon={<ForwardIcon />}
                          hideChildren
                        />
                      </Paper>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                      <Paper className={classes.paper}>
                        <LogCard
                          title={`One Year Inspection - ${
                            selectedProject?.substantialCompletionDate
                              ? formatDate(
                                  dayjs(selectedProject.substantialCompletionDate)
                                    .add(1, 'year')
                                    .toString(),
                                  true,
                                )
                              : 'N/A'
                          }`}
                          headerColor="#74D8D8"
                          icon={<ForwardIcon />}
                          hideChildren
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <div ref={documentIndexRef}>
              <DocumentIndex
                filterFromDate={filterFromDate}
                setFilterFromDate={setFilterFromDate}
                filterToDate={filterToDate}
                setFilterToDate={setFilterToDate}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
                filterAssignTo={filterAssignTo}
                setFilterAssignTo={setFilterAssignTo}
                filterDownload={filterDownload}
                setFilterDownload={setFilterDownload}
                filterView={filterView}
                setFilterView={setFilterView}
                filterProcore={filterProcore}
                setFilterProcore={setFilterProcore}
                filterPunchListStatus={filterPunchListStatus}
                setFilterPunchListStatus={setFilterPunchListStatus}
                filterFromCost={filterFromCost}
                setFilterFromCost={setFilterFromCost}
                filterToCost={filterToCost}
                setFilterToCost={setFilterToCost}
                filterBuilding={filterBuilding}
                setFilterBuilding={setFilterBuilding}
                filterFloor={filterFloor}
                setFilterFloor={setFilterFloor}
                filterSourceFile={filterSourceFile}
                setFilterSourceFile={setFilterSourceFile}
                filterPackage={filterPackage}
                setFilterPackage={setFilterPackage}
                filterDraft={filterDraft}
                setFilterDraft={setFilterDraft}
                filterSubcontractor={filterSubcontractor}
                setFilterSubcontractor={setFilterSubcontractor}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                columnTitles={getHeaders(docType as DocumentTemplateType)}
                documents={documents}
                projectUsers={projectUsers}
                isPlanHolder={isPlanholder}
                uploadWorkflowOpen={uploadWorkflowOpen}
                setUploadWorkflowOpen={setUploadWorkflowOpen}
                visible={visible}
                setVisible={setVisible}
                setIndexDrawerOpen={setIndexDrawerOpen}
              />
              {isPlanholder && (
                <>
                  <PrimeBiddersList />
                  <PlanholderLog />
                </>
              )}
            </div>
          </>
        )}
        <FileUploadDialog
          open={scheduleUploadDialogOpen}
          handleClose={() => setScheduleUploadDialogOpen(false)}
          handleSubmit={handleImportSchedule}
          disableComments
          title="Upload Schedule"
          buttonType="submit"
          allowedExtensions={['.xls', '.xlsx']}
          file={inputSchedule}
          canSubmit={!!inputSchedule}
          addFile={(f) => setInputSchedule(f as File)} // it should always be a File because of diableDesignUpload
          removeFile={() => setInputSchedule(undefined)}
          disableDesignUpload
        />
        <FileUploadDialog
          open={bidTabulationDialogOpen}
          handleClose={() => setBidTabulationDialogOpen(false)}
          title="Upload Bid Tabulation"
          addFile={(file) => setBidTabulationFile(file as File | FileNode)}
          removeFile={() => setBidTabulationFile(undefined)}
          file={bidTabulationFile}
          canSubmit={!!bidTabulationFile}
          buttonType="submit"
          handleSubmit={handleUploadBidTabulation}
          uploadProgress={uploadProgress}
          disableComments
        />
        <AddDocumentDialog
          dialogOpen={addDocumentDialogOpen}
          handleClose={() => setAddDocumentDialogOpen(false)}
        />
        <SubmittalPlaceholderQuestionDialog
          open={submittalsDialogOpen}
          handleClose={() => setSubmittalsDialogOpen(false)}
          submit={(res) => handleGenerateSubmittals(res)}
        />
        <ViewByFloorplanDialog
          open={floorplanDialogOpen}
          onClose={() => setFloorplanDialogOpen(false)}
        />
      </main>
    </>
  );
}
