import React from 'react';
import { Checkbox } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentFileTreeDocument } from '../../scripts/utils';

type Props = {
  type: string;
  document: DocumentFileTreeDocument;
  handleCheck: (checked: boolean, type: string, id: string) => void;
  isDocumentFile?: boolean;
};

const useStyles = makeStyles({
  subTreeItemRoot: {
    '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, &.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  subTreeItemContent: {
    height: 'auto',
  },
  subTreeItemLabel: {
    whiteSpace: 'nowrap',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$selected': {
      backgroundColor: 'transparent',
    },
  },
  subCheckboxRoot: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  treeItemGroup: {
    marginTop: 4,
  },
  bold: {
    fontWeight: 500,
  },
});

export default function DocumentTreeItem(props: Props) {
  const classes = useStyles();
  const { type, document, handleCheck, isDocumentFile } = props;

  const isFile = isDocumentFile || !document.files;

  return (
    <TreeItem
      nodeId={document.id}
      label={
        <div style={{ display: 'flex' }}>
          {document.isPackage && (
            <FontAwesomeIcon icon={faCube} style={{ marginRight: 7, marginTop: 1 }} />
          )}
          {document.title}
        </div>
      }
      icon={
        isFile ? (
          <Checkbox checked={document.checked} classes={{ root: classes.subCheckboxRoot }} />
        ) : null
      }
      onClick={isFile ? () => handleCheck(!document.checked, type, document.id) : undefined}
      classes={{
        root: classes.subTreeItemRoot,
        content: classes.subTreeItemContent,
        label: isFile ? classes.bold : classes.subTreeItemLabel,
        group: classes.treeItemGroup,
      }}
      style={{ marginLeft: isFile ? 4 : 0, marginBottom: isFile ? 0 : 4 }}
    >
      {!!document.files?.length
        ? document.files.map((f) => (
            <DocumentTreeItem
              key={f.id}
              type={type}
              document={f}
              handleCheck={handleCheck}
              isDocumentFile
            />
          ))
        : null}
    </TreeItem>
  );
}
