import {
  ConformingCenterConflictResolutionType,
  DocumentTemplateType,
  FileCategoryType,
  IConformingCenterProjectSummary,
  IFile,
  INumberedDocumentView,
  IProject,
  IProjectView,
  JobStatusType,
  JobType,
} from '../../api-client/autogenerated';
import { getParserJobStatusByObjectKey } from '../../models/api/files';
import { getConformingCenterSummaryByProjectId, publishDocuments } from '../../models/api/dcc';
import { addSnackbar } from '../../features/snackbar/actions';
import React, { Dispatch } from 'react';
import { descendingComparator } from '../document-index/DocumentIndexUtils';

export const yellowHighlight = '#F9E499';
export const redHighlight = '#FC8181';
export const blueHighlight = '#8EB1EF';
export const greenHighlight = '#D7F3E0';

export enum PublishType {
  Drawings,
  Specifications,
  AsBuilts,
  Submittals,
}

export enum TableType {
  Unpublished,
  Custom, //only applies to Specifications
  Deleted,
}

export type MatchType = 'goodMatch' | 'poorMatch';

export enum ConflictType {
  None,
  Unpublished,
  Published,
}

export enum ExpandedJobStatusType {
  CreatingJob = 'creating_job',
  JobCreationFailed = 'job_creation_failed',
  Pending = 'pending',
  Initiated = 'initiated',
  Canceled = 'canceled',
  Complete = 'complete',
}

export interface PreprocessingJobOutput {
  processedFileId: string;
}

export interface FileWithStatus {
  file: IFile;
  status: ExpandedJobStatusType;
  percentComplete?: number | null;
  progress?: { stage: 'optimizing' | 'parsing' };
  output?: PreprocessingJobOutput;
  createdOn?: string | null;
}

export type SimplifiedDocument = Pick<
  INumberedDocumentView,
  | 'id'
  | 'projectId'
  | 'documentTemplateId'
  | 'isDraft'
  | 'title'
  | 'parsedFromFileId'
  | 'sheetNumber'
  | 'sheetNumberConfidence'
  | 'titleConfidence'
  | 'pageNumber'
  | 'submittalSectionFileId'
  | 'drawingsSectionFileId'
>;

export type DCCRow = {
  id: string;
  number: string;
  paragraphNumber?: string;
  title: string;
  sectionDescription?: string;
  pageNumber?: number;
  pageRange?: number[];
  fileName?: string;
  pdfFileId: string;
  parsedFromFileId?: string | null;
  packageName?: string;

  isConflicting: boolean;
  conflictType: ConformingCenterConflictResolutionType;
  isGoodMatch: boolean;
  isChecked: boolean;

  localFile?: File;

  associatedUserIds: string[];
  associatedGroupIds: string[];
};

export type CachedDCCRow = {
  id: string;
  number: string;
  title: string;
  packageName?: string;
  associatedUserIds: string[];
  associatedGroupIds: string[];
};

export enum LocalStorageKeyEnum {
  SpecificationsUnpublished = 'pubCenter-specs-unpublished',
  DrawingsUnpublished = 'pubCenter-drawings-unpublished',
}

function getRowsFromLocalStorage(key: LocalStorageKeyEnum) {
  const rawData = localStorage.getItem(key);
  if (rawData) {
    return JSON.parse(rawData) as CachedDCCRow[];
  }
  return [];
}

export function updateRowsInLocalStorage(key: LocalStorageKeyEnum, rows: DCCRow[]) {
  const cachedRows = getRowsFromLocalStorage(key);
  rows.forEach((newRow) => {
    const rowIndex = cachedRows.findIndex((x) => x.id === newRow.id);
    const { id, number, title, packageName, associatedUserIds, associatedGroupIds } = newRow;
    if (rowIndex !== -1) {
      cachedRows[rowIndex] = {
        ...cachedRows[rowIndex],
        number,
        title,
        packageName,
        associatedUserIds,
        associatedGroupIds,
      };
    } else {
      cachedRows.push({ id, number, title, packageName, associatedUserIds, associatedGroupIds });
    }
  });
  localStorage.setItem(key, JSON.stringify(cachedRows));
}

export function mergeRowsFromCache(key: LocalStorageKeyEnum, currentRows: DCCRow[]) {
  const cachedData = getRowsFromLocalStorage(key);
  if (cachedData.length === 0) return currentRows;

  const newRows = [...currentRows];
  cachedData.forEach((cachedRow) => {
    const rowIndex = newRows.findIndex((r) => r.id === cachedRow.id);
    if (rowIndex !== -1) {
      const { number, title, packageName, associatedUserIds, associatedGroupIds } = cachedRow;
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        number,
        title,
        packageName,
        associatedUserIds,
        associatedGroupIds,
        isChecked: false,
      };
    }
  });
  return newRows;
}

export const PARSER_JOB_EXPIRY_TIME_MS = 1000 * 60 * 45;
export const PREPROCESSOR_JOB_EXPIRY_TIME_MS = 1000 * 60 * 10;

export const getParserJobStatusFromFile = async (
  file: IFile,
  type: JobType,
): Promise<FileWithStatus> => {
  const job = file.url ? await getParserJobStatusByObjectKey(file.url) : null;
  const expiryTime =
    job?.progress?.stage === 'optimizing'
      ? PREPROCESSOR_JOB_EXPIRY_TIME_MS
      : PARSER_JOB_EXPIRY_TIME_MS;
  let expandedJobStatus: ExpandedJobStatusType;
  switch (job?.status) {
    case JobStatusType.Pending:
      expandedJobStatus = ExpandedJobStatusType.Pending;
      break;
    case JobStatusType.Initiated:
      expandedJobStatus = ExpandedJobStatusType.Initiated;
      break;
    case JobStatusType.Canceled:
      expandedJobStatus = ExpandedJobStatusType.Canceled;
      break;
    case JobStatusType.Complete:
      expandedJobStatus = ExpandedJobStatusType.Complete;
      break;
    default:
      if (file.createdOn && Date.now() - Date.parse(file.createdOn) < expiryTime) {
        expandedJobStatus = ExpandedJobStatusType.CreatingJob;
      } else {
        expandedJobStatus = ExpandedJobStatusType.JobCreationFailed;
      }
  }
  if (
    [ExpandedJobStatusType.Pending, ExpandedJobStatusType.Initiated].includes(expandedJobStatus) &&
    file.createdOn &&
    Date.now() - Date.parse(file.createdOn) > expiryTime
  ) {
    // Below logic relies on drawings parser percent_complete updates to not changed updated_on field
    if (
      type === JobType.DrawingsParser &&
      (job?.percentComplete === 100 ||
        (job?.progress.stage === 'parsing' &&
          job.updatedOn &&
          Date.now() - Date.parse(job.updatedOn) < expiryTime))
    ) {
      expandedJobStatus = ExpandedJobStatusType.Initiated;
    } else {
      expandedJobStatus = ExpandedJobStatusType.JobCreationFailed;
    }
  }
  return {
    file,
    status: expandedJobStatus,
    percentComplete: job?.percentComplete,
    progress: job?.progress,
    output: job?.output,
    createdOn: job?.updatedOn || job?.createdOn || file.createdOn,
  };
};

export const getSummaryObjectPropertyNameByType = (type: PublishType) => {
  switch (type) {
    case PublishType.Drawings:
      return 'drawingSummary';
    case PublishType.Specifications:
      return 'specificationSummary';
    case PublishType.AsBuilts:
      return 'asBuiltSummary';
    case PublishType.Submittals:
      return 'submittalSummary';
  }
};

export const getSummaryObjectByType = (
  summary: IConformingCenterProjectSummary,
  type: PublishType,
) => {
  return summary[getSummaryObjectPropertyNameByType(type)];
};

export const getConflictingDocumentIdsByType = (
  summary: IConformingCenterProjectSummary,
  type: PublishType,
) => {
  const conflictingGoodMatches =
    getSummaryObjectByType(summary, type)?.unpublished.goodMatch.conflictingDocumentIds || [];
  const conflictingPoorMatches =
    getSummaryObjectByType(summary, type)?.unpublished.poorMatch.conflictingDocumentIds || [];
  return [...conflictingGoodMatches, ...conflictingPoorMatches];
};

export const getUnpublishedMatchesByType = (
  summary: IConformingCenterProjectSummary,
  type: PublishType,
  matchType: MatchType,
) => {
  const conflictingMatches =
    getSummaryObjectByType(summary, type)?.unpublished[matchType].conflictingDocumentIds || [];
  const nonConflictingMatches =
    getSummaryObjectByType(summary, type)?.unpublished[matchType].nonConflictingDocumentIds || [];
  return [...conflictingMatches, ...nonConflictingMatches];
};

//What the frontend uses to describe the document type on this page.
export const getDocumentAltName = (type: PublishType) => {
  switch (type) {
    case PublishType.Drawings:
      return 'pages';
    case PublishType.Specifications || PublishType.AsBuilts:
      return 'items';
    case PublishType.Submittals:
      return 'placeholders';
    default:
      return 'documents';
  }
};

export const getColorFromJobStatusType = (status: ExpandedJobStatusType) => {
  switch (status) {
    case ExpandedJobStatusType.CreatingJob:
    case ExpandedJobStatusType.Pending:
      return '#BA730F';

    case ExpandedJobStatusType.Initiated:
      return '#4380ED';

    case ExpandedJobStatusType.Complete:
      return '#2BB073';

    case ExpandedJobStatusType.JobCreationFailed:
    case ExpandedJobStatusType.Canceled:
      return '#FF5D45';

    default:
      return '#BA730F';
  }
};

export const getDocumentTemplateTypeFromPublishType = (type: PublishType) => {
  switch (type) {
    case PublishType.Drawings:
      return DocumentTemplateType.Drawings;
    case PublishType.Specifications:
      return DocumentTemplateType.Specifications;
    case PublishType.AsBuilts:
      return DocumentTemplateType.AsBuilt;
    case PublishType.Submittals:
      return DocumentTemplateType.Submittals;
  }
};

export const getDocumentNumberPropertyFromPublishType = (type: PublishType) => {
  switch (type) {
    case PublishType.Drawings:
    case PublishType.AsBuilts:
      return 'sheetNumber';
    case PublishType.Specifications:
    case PublishType.Submittals:
      return 'submittalSection';
  }
};

export const getDocumentTitlePropertyFromPublishType = (type: PublishType) => {
  switch (type) {
    case PublishType.Drawings:
    case PublishType.AsBuilts:
      return 'title';
    case PublishType.Specifications:
    case PublishType.Submittals:
      return 'submittalSectionDescription';
  }
};

export const getDocumentFileIdPropertyFromPublishType = (type: PublishType) => {
  switch (type) {
    case PublishType.Drawings:
    case PublishType.AsBuilts:
      return 'drawingsSectionFileId';
    case PublishType.Specifications:
    case PublishType.Submittals:
      return 'submittalSectionFileId';
  }
};

export const getSortPropertyFromPublishType = (type: PublishType) => {
  switch (type) {
    case PublishType.Drawings:
      return 'pageNumber';
    case PublishType.Submittals:
    case PublishType.Specifications:
    default:
      return 'number';
  }
};

export const getUserFriendlyDocumentNameFromPublishType = (type: PublishType) => {
  switch (type) {
    case PublishType.Drawings:
      return 'Drawing';
    case PublishType.Specifications:
      return 'Specification';
    case PublishType.AsBuilts:
      return 'As-built';
    case PublishType.Submittals:
      return 'Submittal';
  }
};

export const getCurrentParserStep = (fileWithStatus?: FileWithStatus) => {
  if (!fileWithStatus?.percentComplete) return '0';

  const { percentComplete } = fileWithStatus;

  if (percentComplete < 20) {
    return '0';
  } else if (percentComplete < 40) {
    return '1';
  } else if (percentComplete < 60) {
    return '2';
  } else if (percentComplete < 80) {
    return '3';
  } else {
    return '4';
  }
};

export const getCurrentPreprocessorStep = (fileWithStatus?: FileWithStatus) => {
  if (!fileWithStatus?.percentComplete) return '0';

  const { percentComplete } = fileWithStatus;

  if (percentComplete < 25) {
    return '0';
  } else if (percentComplete < 50) {
    return '1';
  } else if (percentComplete < 75) {
    return '2';
  } else {
    return '3';
  }
};

export const getTotalDocs = (pieData: Array<number>) => {
  return pieData.reduce((prevVal: number, currVal: number) => prevVal + currVal, 0);
};

export const getPieDataByType = (summary: IConformingCenterProjectSummary, type: PublishType) => {
  const unpublishedGoodMatchesCount =
    getSummaryObjectByType(summary, type)?.unpublished?.goodMatch?.nonConflictingDocumentIds
      ?.length || 0;
  const unpublishedPoorMatchesCount =
    getSummaryObjectByType(summary, type)?.unpublished?.poorMatch?.nonConflictingDocumentIds
      ?.length || 0;
  const conflictingMatchesCount = getConflictingDocumentIdsByType(summary, type).length;
  return [unpublishedGoodMatchesCount, unpublishedPoorMatchesCount, conflictingMatchesCount];
};

export const getButtonIsDisabled = (
  files: FileWithStatus[],
  pieData: Array<number>,
  pieDataIndex: number | undefined,
) => {
  return (
    getTotalDocs(pieData) === 0 ||
    (pieDataIndex !== undefined && pieData[pieDataIndex] === 0) ||
    files.some(
      ({ status }) =>
        status !== ExpandedJobStatusType.Complete &&
        status !== ExpandedJobStatusType.Canceled &&
        status !== ExpandedJobStatusType.JobCreationFailed,
    )
    // && index !== buttons.length - 1
  );
};

export const getButtonBackgroundColor = (color: string, isDisabled: boolean) => {
  if (isDisabled) return '#E0E0E0';
  return color;
};

export const publish = async (
  summary: IConformingCenterProjectSummary | undefined,
  currentProject: IProject | null,
  type: PublishType,
  setIsPublishing: (val: boolean) => void,
  fetchSummary: (projectId: string) => Promise<IConformingCenterProjectSummary>,
  dispatch: Dispatch<any>,
  options: {
    publishGoodMatches?: boolean;
    publishPoorMatches?: boolean;
    publishExistingMatches?: boolean;
  } = {},
) => {
  if (!summary || !currentProject) return;
  setIsPublishing(true);
  const { publishGoodMatches, publishPoorMatches, publishExistingMatches } = options;
  //shorthand
  const unpublished = getUnpublished(type, summary);

  //getting list of documents to publish
  let documentIdsToPublish: string[] = [];

  if (publishGoodMatches)
    documentIdsToPublish = documentIdsToPublish.concat(
      unpublished?.goodMatch.nonConflictingDocumentIds || [],
    );
  if (publishPoorMatches)
    documentIdsToPublish = documentIdsToPublish.concat(
      unpublished?.poorMatch.nonConflictingDocumentIds || [],
    );
  if (publishExistingMatches)
    documentIdsToPublish = documentIdsToPublish
      .concat(unpublished?.poorMatch.conflictingDocumentIds || [])
      .concat(unpublished?.goodMatch.conflictingDocumentIds || []);

  try {
    //the endpoint returns document ids if there were conflicts.
    const unsuccessful = await publishDocuments(documentIdsToPublish);

    //If there were no conflicts, simply show success message.
    if (unsuccessful.length === 0) {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully published all ${documentIdsToPublish.length} ${getDocumentAltName(
            type,
          )}!`,
          severity: 'success',
        }),
      );
    } else {
      // If endpoint returns unsuccessful document ids, we will set the summary state to include these documents
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Some ${getDocumentAltName(
            type,
          )} were not able to be published due to conflicts. Please press View More Details to review these ${getDocumentAltName(
            type,
          )}.`,
          severity: 'warning',
        }),
      );
    }
    await fetchSummary(currentProject.id);
  } finally {
    setIsPublishing(false);
  }
};

export const getUnpublished = (type: PublishType, sum: IConformingCenterProjectSummary) => {
  return getSummaryObjectByType(sum, type)?.unpublished;
};

export const fetchFilesWithStatus = async (
  project: IProjectView,
  summary: IConformingCenterProjectSummary,
  type: PublishType.Drawings | PublishType.Specifications,
) => {
  const filterFilesWithStatus = (
    { file, status }: FileWithStatus,
    type: PublishType.Drawings | PublishType.Specifications,
  ) => {
    if (
      summary.summaryByParsedFromFileId[file.id] &&
      summary.summaryByParsedFromFileId[file.id][
        type === PublishType.Drawings ? 'drawingSummary' : 'specificationSummary'
      ].unpublished.numDocuments > 0
    )
      return true;
    if (file.isHiddenInPublishingCenter) return false;
    if (file.isVerified || type === PublishType.Drawings) {
      return [
        ExpandedJobStatusType.Pending,
        ExpandedJobStatusType.Initiated,
        ExpandedJobStatusType.Canceled,
        ExpandedJobStatusType.CreatingJob,
        ExpandedJobStatusType.JobCreationFailed,
      ].includes(status);
    }
    return false;
  };

  const files =
    project.files?.filter(
      (f) =>
        !f.isHiddenInPublishingCenter &&
        f.category ===
          (type === PublishType.Drawings
            ? FileCategoryType.Drawings
            : FileCategoryType.Specifications),
    ) || [];

  const filesWithStatus = await Promise.all(
    files.map((f) =>
      getParserJobStatusFromFile(
        f,
        type === PublishType.Drawings ? JobType.DrawingsParser : JobType.SpecificationsParser,
      ),
    ),
  );

  return filesWithStatus.filter((fs) => filterFilesWithStatus(fs, type));
};

export const pollForStatus = async (
  files: FileWithStatus[],
  setFiles: React.Dispatch<React.SetStateAction<FileWithStatus[]>>,
  type: JobType,
  callback: () => Promise<any>,
) => {
  const jobsToUpdate = files.filter(
    ({ status }) =>
      ![
        ExpandedJobStatusType.Complete,
        ExpandedJobStatusType.Canceled,
        ExpandedJobStatusType.JobCreationFailed,
      ].includes(status),
  );

  if (jobsToUpdate.length > 0) {
    const results = await Promise.all(
      jobsToUpdate.map(async ({ file }) => getParserJobStatusFromFile(file, type)),
    );

    let tempFiles = [...files];
    results.forEach((x) => {
      const index = tempFiles.findIndex((f) => f.file.id === x.file.id);
      if (index !== -1) {
        tempFiles = [...tempFiles.slice(0, index), x, ...tempFiles.slice(index + 1)];
      }
    });

    setFiles(tempFiles);
    if (
      results.some(
        (r) =>
          r.status === ExpandedJobStatusType.Canceled ||
          r.status === ExpandedJobStatusType.Complete ||
          r.status === ExpandedJobStatusType.JobCreationFailed,
      )
    ) {
      await callback();
    }
  }
};

export const canUploadToPublishingCenter = async (
  project: IProjectView,
  type: PublishType.Drawings | PublishType.Specifications,
) => {
  const summary = await getConformingCenterSummaryByProjectId(project.id);
  const files = await fetchFilesWithStatus(project, summary, type);
  const currentFile = files.sort((a, b) => descendingComparator(a.file, b.file, 'createdOn'))[0];
  return !(
    !!currentFile &&
    ![ExpandedJobStatusType.JobCreationFailed, ExpandedJobStatusType.Canceled].includes(
      currentFile.status,
    )
  );
};
