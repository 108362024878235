import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDisableProcoreIntegrationFeatures,
  getProjectState,
} from '../../features/project/selectors';
import { IConformingCenterProjectSummary } from '../../api-client/autogenerated';
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import { generatePlaceholders, getConformingCenterSummaryByProjectId } from '../../models/api/dcc';
import { addSnackbar } from '../../features/snackbar/actions';
import { getDocumentAltName, getSummaryObjectByType, PublishType } from './DCCUtils';
import SubmittalPlaceholderQuestionDialog from './SubmittalPlaceholderQuestionDialog';
import FullscreenLoader from '../loader/FullscreenLoader';
import CircularLoader from '../loader/CircularLoader';
import { Add } from '@material-ui/icons';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import PublishingCenterDrawings from './PublishingCenterDrawings';
import PublishingCenterSpecifications from './PublishingCenterSpecifications';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    paper: {
      color: theme.palette.text.secondary,
      height: '100%',
      boxShadow: '5px 5px 15px rgb(0, 0, 0, .15)',
      width: '100%',
    },
    titleStyle: {
      background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
      borderRadius: '4px 4px 0px 0px',
    },
    cardBody: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
    },
  }),
);

const ENABLE_AS_BUILTS = true;
const ENABLE_SUBMITTALS = true;

export default function PublishingCenter() {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const currentProject = useSelector(getProjectState);
  const disableProcoreIntegrationFeatures = useSelector(getDisableProcoreIntegrationFeatures);

  const [isLoading, setIsLoading] = useState(true);
  const [isPublishing, setIsPublishing] = useState<PublishType | null>(null);
  const [submittalsDialogOpen, setSubmittalsDialogOpen] = useState(false);
  const [summary, setSummary] = useState<IConformingCenterProjectSummary>();

  const canGenerateAsBuilts =
    !!summary && summary.numAsBuiltsBeforeUnpublishedWithPublishedDrawing > 0;

  const fetchSummary = async (projectId: string) => {
    const s = await getConformingCenterSummaryByProjectId(projectId);
    setSummary(s);
    return s;
  };

  useEffect(() => {
    if (currentProject) {
      setIsLoading(true);
      fetchSummary(currentProject.id).finally(() => setIsLoading(false));
    }
  }, [currentProject?.id]);

  const handleSubmittalsMoreDetails = () => {
    history.push(`pub-center/conform-submittals`);
  };

  const handleGeneratePlaceholders = async (
    type: PublishType.Submittals | PublishType.AsBuilts,
    generateForFirstDivisions?: boolean,
  ) => {
    setSubmittalsDialogOpen(false);
    if (!currentProject || !summary) return;

    setIsPublishing(PublishType.AsBuilts);

    const documentIds =
      getSummaryObjectByType(
        summary,
        type === PublishType.Submittals ? PublishType.Specifications : PublishType.Drawings,
      )?.published.documentIds || [];

    try {
      const generatedPlaceholderDocumentIds = await generatePlaceholders(documentIds, {
        ignoredSpecificationDivisions:
          type === PublishType.Submittals && !generateForFirstDivisions ? ['00', '01'] : [],
      });

      dispatch(
        addSnackbar({
          id: Date.now(),
          message: `Successfully generated ${
            generatedPlaceholderDocumentIds.length
          } ${getDocumentAltName(type)}!`,
          severity: 'success',
        }),
      );

      if (type === PublishType.Submittals) {
        handleSubmittalsMoreDetails();
      } else {
        await fetchSummary(currentProject.id);
      }
    } catch (e: any) {
      if (type === PublishType.AsBuilts && e?.message?.toLowerCase().includes('network error')) {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message:
              'Your documents are still being created. Check the As-Built Drawings table in a few minutes to see the complete results.',
            severity: 'info',
          }),
        );
      }
    } finally {
      setIsPublishing(null);
    }
  };

  const handleCreateSubmittals = async () => {
    if (summary?.submittalSummary?.beforeUnpublished?.numDocuments) {
      setSubmittalsDialogOpen(true);
    } else {
      handleSubmittalsMoreDetails();
    }
  };

  return (
    <main className={classes.root}>
      <NavAppbar />
      <Grid container>
        <Grid item xs={12}>
          <PageTitle title="Publishing and Conforming Center" />
        </Grid>
        {!isLoading ? (
          <Grid container spacing={3}>
            {/* Drawings */}
            <PublishingCenterDrawings
              pageIsLoading={isLoading}
              setPageIsLoading={setIsLoading}
              summary={summary}
              fetchSummary={() => fetchSummary(currentProject!.id)}
            />

            {/* Specifications */}
            <PublishingCenterSpecifications
              pageIsLoading={isLoading}
              setPageIsLoading={setIsLoading}
              summary={summary}
              fetchSummary={() => fetchSummary(currentProject!.id)}
            />

            {/* As Built */}
            {ENABLE_AS_BUILTS && (
              <Grid container item xs={12} lg={6}>
                <Grid container item xs={12}>
                  <Paper className={classes.paper}>
                    <Card style={{ height: '100%' }}>
                      <CardContent className={classes.titleStyle}>
                        <h2 style={{ textAlign: 'left', color: '#FFFFFF', margin: 0 }}>
                          {' '}
                          As-Built Placeholders{' '}
                        </h2>
                      </CardContent>
                      <CardContent className={classes.cardBody}>
                        <Typography align="center" style={{ width: '85%', marginBottom: 8 }}>
                          We found {summary?.numAsBuiltsBeforeUnpublishedWithPublishedDrawing}{' '}
                          drawings in your uploaded drawings document.
                        </Typography>
                        <Typography align="center" style={{ width: '85%', marginBottom: 8 }}>
                          Would you like to create As-Built placeholders for each one?
                        </Typography>
                        <Typography align="center" style={{ width: '85%', marginBottom: 16 }}>
                          Customize As-Built placeholders in the Close Out tab.
                        </Typography>
                        {isPublishing === PublishType.AsBuilts ? (
                          <CircularLoader />
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={!canGenerateAsBuilts}
                            startIcon={<Add />}
                            onClick={() => handleGeneratePlaceholders(PublishType.AsBuilts)}
                            style={{ whiteSpace: 'nowrap', lineHeight: 1, width: '85%' }}
                          >
                            Create As-Built Placeholders
                          </Button>
                        )}
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              </Grid>
            )}

            {/* Submittal Placeholders */}
            {ENABLE_SUBMITTALS && (
              <Grid container item xs={12} lg={6}>
                <Grid container item xs={12}>
                  <Paper className={classes.paper}>
                    <Card style={{ height: '100%' }}>
                      <CardContent className={classes.titleStyle}>
                        <h2 style={{ textAlign: 'left', color: '#FFFFFF', margin: 0 }}>
                          {' '}
                          Submittal Placeholders{' '}
                        </h2>
                      </CardContent>
                      <CardContent className={classes.cardBody}>
                        <Typography align="center" style={{ width: '85%', marginBottom: 8 }}>
                          We found {summary?.numSubmittalsNotPublishedWithPublishedSpecification}{' '}
                          submittals in your published specifications.
                        </Typography>
                        <Typography align="center" style={{ width: '85%', marginBottom: 8 }}>
                          Would you like to create Submittal placeholders for these items?
                        </Typography>
                        <Typography align="center" style={{ width: '85%', marginBottom: 16 }}>
                          Customize Submittal placeholders from the Submittals page at any time.
                        </Typography>
                        <TooltipIfDisabledComponent
                          title="This is disabled for projects synced with Procore"
                          disabled={disableProcoreIntegrationFeatures}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={
                              disableProcoreIntegrationFeatures ||
                              !summary?.numSubmittalsNotPublishedWithPublishedSpecification
                            }
                            onClick={handleCreateSubmittals}
                            style={{ width: '85%', whiteSpace: 'nowrap' }}
                          >
                            Review Submittal Placeholders Before Publishing
                          </Button>
                        </TooltipIfDisabledComponent>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <FullscreenLoader />
        )}
      </Grid>
      <SubmittalPlaceholderQuestionDialog
        open={submittalsDialogOpen}
        handleClose={() => setSubmittalsDialogOpen(false)}
        submit={(res) => handleGeneratePlaceholders(PublishType.Submittals, res)}
      />
    </main>
  );
}
