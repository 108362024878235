import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, createStyles, Dialog, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { PDFPageProxy } from 'react-pdf';
import Typography from '@material-ui/core/Typography';
import FullscreenLoader from '../loader/FullscreenLoader';
import { CropConfig } from '../../api-client/autogenerated';
import tutorial1 from '../../images/part1.mp4';
import tutorial2 from '../../images/part2.mp4';
import { isDev } from '../../scripts/utils';
import { SubmitButton } from '../custom-components/CustomButtons';
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons';
import CircularLoader from '../loader/CircularLoader';

type Rectangle = {
  x: number;
  y: number;
  W: number;
  H: number;
};

type CropRegion = {
  x: number;
  y: number;
  W: number;
  H: number;
  p: number;
  skip?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    absolute: {
      position: 'absolute',
    },
    rootContainer: {
      display: 'flex',
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
    },
    canvasContainer: {
      position: 'relative',
      flexShrink: 0,
    },
    infoContainer: {
      position: 'absolute',
      padding: '0px 16px 0px 8px',
      zIndex: 20,
      pointerEvents: 'none',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '12px',
      height: '100%',
      '& > *': {
        marginBottom: '3%',
        width: '90%',
      },
      maxWidth: '25%',
    },
    instructions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      background: 'rgba(128, 128, 128, 0.95)',
      zIndex: 100,
    },
    steps: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '48%',
      height: '100%',
    },
    instructionText: {
      width: '100%',
      fontSize: '28px',
      lineHeight: '30px',
      textAlign: 'left',
      color: '#E7E7E7',
      marginLeft: '60px',
      paddingInlineStart: 0,
    },
    video: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '50%',
      height: '100%',
    },
    buttonContainerText: {
      fontSize: '18px',
      lineHeight: '20px',
    },
    listItemText: {
      fontSize: 18,
      lineHeight: '20px',
      marginLeft: 32,
      marginBottom: 6,
    },
  }),
);

const DPI = 300;
// used to convert points to pixels for use in pdftocairo for cropping
const scaleFactor = DPI / 72;

type Props = {
  open: boolean;
  file?: File | Blob;
  handleClose: () => void;
  onSubmit: (titleBlockCropConfig: CropConfig, sheetNameCropConfig: CropConfig) => void;
};

export default function DrawingsDialog(props: Props) {
  const classes = useStyles();
  const { open, file, handleClose, onSubmit } = props;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rectangle = useRef<Rectangle>({ x: 0, y: 0, W: 0, H: 0 });

  const [instructionsOpen, setInstructionsOpen] = useState(true);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [renderMode, setRenderMode] = useState<'canvas' | 'svg'>('canvas');
  const [pages, setPages] = useState(0);
  const [canvasWidth, _setCanvasWidth] = useState(0);
  const setCanvasWidth = (w: number) => {
    canvasRef.current!.width = w;
    _setCanvasWidth(w);
  };
  const [canvasHeight, _setCanvasHeight] = useState(0);
  const setCanvasHeight = (h: number) => {
    canvasRef.current!.height = h;
    _setCanvasHeight(h);
  };
  const [_pageNum, _setPageNum] = useState(1);
  const pageNum = useRef(_pageNum);
  const setPageNum = (p: number) => {
    pageNum.current = p;
    _setPageNum(p);
  };
  const [_pdfScale, _setPdfScale] = useState(1);
  const pdfScale = useRef(_pdfScale);
  const setPdfScale = (s: number) => {
    pdfScale.current = s;
    _setPdfScale(s);
  };
  const [_point, _setPoint] = useState([0, 0]);
  const point = useRef(_point);
  const setPoint = (p: number[]) => {
    point.current = p;
    _setPoint(p);
  };
  const [_rectWidth, _setRectWidth] = useState(0);
  const rectWidth = useRef(_rectWidth);
  const setRectWidth = (w: number) => {
    rectWidth.current = w;
    _setRectWidth(w);
  };
  const [_rectHeight, _setRectHeight] = useState(0);
  const rectHeight = useRef(_rectHeight);
  const setRectHeight = (h: number) => {
    rectHeight.current = h;
    _setRectHeight(h);
  };

  const [_cropType, _setCropType] = useState<'number' | 'title'>('number');
  const cropType = useRef(_cropType);
  const setCropType = (type: 'number' | 'title') => {
    cropType.current = type;
    _setCropType(type);
  };

  const [_numberRegions, _setNumberRegions] = useState<CropRegion[]>([]);
  const numberRegions = useRef(_numberRegions);
  const setNumberRegions = (r: CropRegion[]) => {
    numberRegions.current = r;
    _setNumberRegions(r);
  };
  const [_titleRegions, _setTitleRegions] = useState<CropRegion[]>([]);
  const titleRegions = useRef(_titleRegions);
  const setTitleRegions = (r: CropRegion[]) => {
    titleRegions.current = r;
    _setTitleRegions(r);
  };

  const getRegions = () => {
    return (cropType.current === 'number' ? numberRegions : titleRegions).current;
  };

  const setRegions = cropType.current === 'number' ? setNumberRegions : setTitleRegions;

  const getCurrentRegion = () => {
    return getRegions().find((r) => r.p === pageNum.current);
  };

  const getCurrentRegionIndex = () => {
    return getRegions().findIndex((r) => r.p === pageNum.current);
  };

  const isCurrentRegionValid = () => {
    const currentRegion = getCurrentRegion();
    return (
      !!currentRegion &&
      (currentRegion.skip ||
        (currentRegion.y && currentRegion.x && currentRegion.W && currentRegion.H))
    );
  };

  const draw = () => {
    canvasRef.current!.getContext('2d')!.strokeStyle =
      cropType.current === 'number' ? '#0947B9' : '#F28B00';
    canvasRef.current!.getContext('2d')!.lineWidth = 2;
    canvasRef
      .current!.getContext('2d')!
      .strokeRect(
        rectangle.current.x,
        rectangle.current.y,
        rectangle.current.W,
        rectangle.current.H,
      );
  };

  const mouseDown = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    // console.log('Mouse down', event);
    canvasRef
      .current!.getContext('2d')!
      .clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    const x = event.pageX - canvasRef.current!.offsetLeft;
    const y = event.pageY - canvasRef.current!.offsetTop;
    rectangle.current = { x, y, W: 0, H: 0 };
  };

  const mouseMove = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    // console.log('mouse move');
    if (event.buttons > 0) {
      const W = event.pageX - canvasRef.current!.offsetLeft - rectangle.current.x;
      const H = event.pageY - canvasRef.current!.offsetTop - rectangle.current.y;
      rectangle.current = { ...rectangle.current, W, H };
      const pointX = W >= 0 ? rectangle.current.x : rectangle.current.x + W;
      const pointY = H >= 0 ? rectangle.current.y : rectangle.current.y + H;
      setPoint([pointX, pointY]);
      setRectWidth(Math.abs(W));
      setRectHeight(Math.abs(H));
      canvasRef
        .current!.getContext('2d')!
        .clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
      draw();
    }
  };

  const mouseUp = () => {
    // console.log('mouse up');
    if (rectWidth.current && rectHeight.current) {
      const newRegion = {
        x: Math.round(point.current[0] * (scaleFactor / pdfScale.current)),
        y: Math.round(point.current[1] * (scaleFactor / pdfScale.current)),
        W: Math.round(rectWidth.current * (scaleFactor / pdfScale.current)),
        H: Math.round(rectHeight.current * (scaleFactor / pdfScale.current)),
        p: pageNum.current,
        skip: false,
      };
      const existingIndex = getCurrentRegionIndex();
      if (existingIndex !== -1) {
        console.log('Replacing region...', newRegion);
        setRegions([
          ...getRegions().slice(0, existingIndex),
          newRegion,
          ...getRegions().slice(existingIndex + 1),
        ]);
      } else {
        console.log('Adding new region...', newRegion);
        console.log(setRegions);
        setRegions([...getRegions(), newRegion]);
      }
    }
  };

  const clearRegion = () => {
    rectangle.current = {} as Rectangle;
    canvasRef
      .current!.getContext('2d')!
      .clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    setPoint([0, 0]);
    setRectHeight(0);
    setRectWidth(0);
  };

  const changeCropType = () => {
    if (cropType.current === 'number') {
      setCropType('title');
    } else {
      setCropType('number');
    }
    setPageNum(1);
    clearRegion();
  };

  const handleSubmit = () => {
    if (pageNum.current < pages) {
      setIsChecking(true);
      setPageNum(pageNum.current + 1);
      return;
    }

    if (cropType.current === 'number') {
      changeCropType();
      setInstructionsOpen(true);
    } else {
      setIsSubmitting(true);
      const titleBlockJson: CropConfig = {
        resolution: DPI,
        crops: numberRegions.current.map(({ x, y, W, H, p, skip }) => {
          return { x, y, W, H, p, skip };
        }),
      };
      const sheetNameJson: CropConfig = {
        resolution: DPI,
        crops: titleRegions.current.map(({ x, y, W, H, p, skip }) => {
          return { x, y, W, H, p, skip };
        }),
      };
      onSubmit(titleBlockJson, sheetNameJson);
    }
  };

  const handlePreviousPage = () => {
    if (pageNum.current - 1 > 0) {
      setPageNum(pageNum.current - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNum.current <= pages) {
      setPageNum(pageNum.current + 1);
    }
  };

  const drawRegion = (region: CropRegion) => {
    canvasRef
      .current!.getContext('2d')!
      .clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    setPoint([
      region.x / (scaleFactor / pdfScale.current),
      region.y / (scaleFactor / pdfScale.current),
    ]);
    setRectWidth(region.W / (scaleFactor / pdfScale.current));
    setRectHeight(region.H / (scaleFactor / pdfScale.current));
    canvasRef.current!.getContext('2d')!.strokeStyle =
      cropType.current === 'number' ? '#0947B9' : '#F28B00';
    canvasRef.current!.getContext('2d')!.lineWidth = 2;
    canvasRef
      .current!.getContext('2d')!
      .strokeRect(
        region.x / (scaleFactor / pdfScale.current),
        region.y / (scaleFactor / pdfScale.current),
        region.W / (scaleFactor / pdfScale.current),
        region.H / (scaleFactor / pdfScale.current),
      );
  };

  const toggleSkip = (checked: boolean) => {
    clearRegion();
    const regions = [...getRegions()];
    const currentRegionIndex = regions.findIndex((r) => r.p === pageNum.current);
    if (currentRegionIndex !== -1) {
      setRegions([
        ...regions.slice(0, currentRegionIndex),
        { ...regions[currentRegionIndex], skip: checked, H: 0, W: 0, x: 0, y: 0 },
        ...regions.slice(currentRegionIndex + 1),
      ]);
    } else {
      const newRegion = { x: 0, y: 0, W: 0, H: 0, p: pageNum.current, skip: checked };
      setRegions([...getRegions(), newRegion]);
    }
  };

  const handlePageLoad = (page: PDFPageProxy) => {
    const { rotate, pageNumber } = page;
    // const { originalHeight, originalWidth } = page;
    const originalWidth =
      (page._pageInfo.view?.[2] || 0) - (page._pageInfo.view?.[0] || 0) ||
      page.getViewport({ scale: 1 }).width;
    const originalHeight =
      (page._pageInfo.view?.[3] || 0) - (page._pageInfo.view?.[1] || 0) ||
      page.getViewport({ scale: 1 }).height;
    const isRotated = rotate === 90 || rotate === 270;
    const scale = Math.min(
      (window.innerWidth * 0.8) / (isRotated ? originalHeight : originalWidth),
      (window.innerHeight * 0.95) / (isRotated ? originalWidth : originalHeight),
    );
    setPdfScale(scale);
    if (isRotated) {
      setCanvasWidth(originalHeight * scale);
      setCanvasHeight(originalWidth * scale);
    } else {
      setCanvasWidth(originalWidth * scale);
      setCanvasHeight(originalHeight * scale);
    }
    // console.log('canvasWidth', canvasWidth, canvasWidth / scale);
    // console.log('canvasHeight', canvasHeight, canvasHeight / scale);
    // console.log('originalWidth', originalWidth, originalWidth * scale);
    // console.log('originalHeight', originalHeight, originalHeight * scale);
    const existingRegion = getCurrentRegion();
    if (existingRegion) {
      console.log('Loading existing region...', existingRegion);
      drawRegion(existingRegion);
    }
    if (
      (canvasWidth !== (!isRotated ? originalWidth : originalHeight) * scale ||
        canvasHeight !== (!isRotated ? originalHeight : originalWidth) * scale) &&
      pageNumber > 1
    ) {
      console.log('page size changed!');
      setIsChecking(false);
      setPoint([0, 0]);
      setRectWidth(0);
      setRectHeight(0);
      return;
    }
    if (!existingRegion && pageNumber !== 1) {
      const newRegion: CropRegion = {
        x: Math.round(point.current[0] * (scaleFactor / pdfScale.current)),
        y: Math.round(point.current[1] * (scaleFactor / pdfScale.current)),
        W: Math.round(rectWidth.current * (scaleFactor / pdfScale.current)),
        H: Math.round(rectHeight.current * (scaleFactor / pdfScale.current)),
        p: pageNumber,
        skip: getRegions().find((r) => r.p === pageNumber - 1)?.skip || false,
      };
      console.log('Adding new region...', newRegion);
      setRegions([...getRegions(), newRegion]);
      drawRegion(newRegion);
    }

    if (isChecking) {
      if (pageNum.current < pages && isCurrentRegionValid()) {
        setPageNum(pageNum.current + 1);
      } else {
        setIsChecking(false);
      }
    }
  };

  useEffect(() => {
    if (open) window.scrollTo(0, 0);
  }, [open]);

  const getNextPageDisabled = () => {
    if (pageNum.current >= pages) return true;
    const region = getCurrentRegion();
    if (region?.skip) return false;
    if (!region || !region.H || !region.W || !region.x || !region.y) return true;
  };

  const getNumberOfRegionsIdentified = () => {
    return getRegions().filter((r) => r.skip || (r.y && r.x && r.W && r.H)).length;
  };

  console.log(getRegions());

  const canSubmit = () =>
    isCurrentRegionValid() ||
    (getRegions().length === pages &&
      getRegions().every((r) => r.skip || (r.y && r.x && r.W && r.H)));

  const getTutorial = () => {
    const isFirstStep = cropType.current === 'number';
    return (
      <>
        <Typography component="ul" className={classes.instructionText}>
          Step {isFirstStep ? '1' : '2'} of 2: Identify the Drawing{' '}
          {isFirstStep ? 'Number' : 'Title'}
        </Typography>
        <Typography component="li" className={classes.instructionText} style={{ marginLeft: 140 }}>
          Click and Drag to create a{' '}
          <strong style={{ fontWeight: 500, color: isFirstStep ? '#0947B9' : '#F28B00' }}>
            {isFirstStep ? 'BLUE' : 'ORANGE'}
          </strong>{' '}
          rectangle around the Drawing {isFirstStep ? 'Number' : 'Title'}.
        </Typography>
        <Typography component="li" className={classes.instructionText} style={{ marginLeft: 140 }}>
          Click "Exclude This Sheet" to exclude the sheet.
        </Typography>
        <Typography component="li" className={classes.instructionText} style={{ marginLeft: 140 }}>
          Note: if the dimensions of the sheet change, CENTERLINE will prompt you for more
          information until all sheet {isFirstStep ? 'numbers' : 'titles'} have been identified.
        </Typography>
        <Typography
          component="li"
          className={classes.instructionText}
          style={{ marginBottom: 32, marginLeft: 140 }}
        >
          Click “Submit {isFirstStep ? 'Drawing Number Location' : 'Title Locations For All'}”
        </Typography>
      </>
    );
  };

  const getStep1Instructions = () => {
    return (
      <div style={{ border: '1px solid #000', padding: 8 }}>
        <Typography style={{ fontSize: 20, marginBottom: 10 }}>
          Step 1 of 2: Identify the Drawing Number
        </Typography>
        <Typography component="li" className={classes.listItemText}>
          Click and Drag to create a{' '}
          <strong style={{ fontWeight: 500, color: '#0947B9' }}>BLUE</strong> rectangle around the
          Drawing Number.
        </Typography>
        <Typography component="li" className={classes.listItemText}>
          Click "Exclude This Sheet" to exclude this sheet.
        </Typography>
        <Typography component="li" className={classes.listItemText}>
          Note: if the dimensions of the sheet change, CENTERLINE will prompt you for more
          information until all sheet numbers have been identified.
        </Typography>
        <Typography component="li" className={classes.listItemText}>
          Click “Submit Drawing Number Location”
        </Typography>
      </div>
    );
  };

  const getStep2Instructions = () => {
    return (
      <div style={{ border: '1px solid #000', padding: 8 }}>
        <Typography style={{ fontSize: 18, marginBottom: 10 }}>
          Step 2 of 2: Identify the Drawing Title
        </Typography>
        <Typography component="li" className={classes.listItemText}>
          Click and Drag to create an{' '}
          <strong style={{ fontWeight: 500, color: '#F28B00' }}>ORANGE</strong> rectangle around the
          Drawing Title.
        </Typography>
        <Typography component="li" className={classes.listItemText}>
          Click "Exclude This Sheet" to exclude this sheet.
        </Typography>
        <Typography component="li" className={classes.listItemText}>
          Note: if the dimensions of the sheet change, CENTERLINE will prompt you for more
          information until all sheet titles have been identified.
        </Typography>
        <Typography component="li" className={classes.listItemText}>
          Click “Submit Title Locations For All”
        </Typography>
      </div>
    );
  };

  return (
    <Dialog open={open} fullScreen>
      <div className={classes.rootContainer}>
        {instructionsOpen && (
          <div className={classes.instructions}>
            <div className={classes.steps}>
              {getTutorial()}
              <Button
                variant="contained"
                color="primary"
                disabled={!finishedLoading}
                onClick={() => setInstructionsOpen(false)}
                style={{ width: '40%', height: '5%', fontSize: '21px', fontWeight: 600 }}
              >
                I understand
              </Button>
            </div>
            <div className={classes.video}>
              <video
                controls
                playsInline
                muted
                autoPlay
                loop
                width="87%"
                style={{
                  border: '2px solid black',
                  borderBottom: '4px solid black',
                  marginTop: '20%',
                  marginLeft: '2%',
                }}
              >
                <source src={cropType.current === 'number' ? tutorial1 : tutorial2} />
              </video>
            </div>
          </div>
        )}
        <div
          className={classes.canvasContainer}
          style={{ width: canvasWidth, height: canvasHeight }}
        >
          {isDev() && (
            <div className={classes.infoContainer}>
              <p>
                <strong>Type: {cropType.current}</strong>
              </p>
              <p>
                <strong>Mode: {renderMode}</strong>
              </p>
              <p>
                <strong>(x, y): </strong> (
                {Math.floor(point.current[0] * (scaleFactor / pdfScale.current))},
                {Math.floor(point.current[1] * (scaleFactor / pdfScale.current))})
              </p>
              <p>
                <strong>(W, H): </strong> (
                {Math.ceil(rectWidth.current * (scaleFactor / pdfScale.current))}X
                {Math.ceil(rectHeight.current * (scaleFactor / pdfScale.current))})
              </p>
            </div>
          )}
          {isChecking ? (
            <Typography
              align="center"
              style={{
                position: 'absolute',
                width: '80%',
                top: '40%',
                left: '10%',
                fontSize: 42,
                lineHeight: '44px',
              }}
            >
              SCANNING for EXCEPTIONS...
            </Typography>
          ) : null}
          {file && (
            <Document
              file={file}
              renderMode={renderMode}
              loading={<FullscreenLoader />}
              onLoadSuccess={({ numPages }) => {
                setPages(numPages);
                setFinishedLoading(true);
              }}
              onLoadError={(e) => console.error('LOAD', e)}
              onSourceError={(e) => console.error('SOURCE', e)}
              className={classes.absolute}
            >
              <Page
                pageNumber={pageNum.current}
                scale={pdfScale.current}
                renderAnnotationLayer={false}
                onLoadSuccess={handlePageLoad}
                onGetAnnotationsError={(e) => console.error('ANNOTATIONS', e)}
                onGetTextError={(e) => console.error('TEXT', e)}
                onLoadError={(e) => console.error('LOAD', e)}
                onRenderError={(e) => console.error('RENDER', e)}
              />
            </Document>
          )}
          <canvas
            ref={canvasRef}
            className={classes.absolute}
            onMouseDown={mouseDown}
            onMouseUp={mouseUp}
            onMouseMove={mouseMove}
            style={{ zIndex: 10 }}
          />
          {getCurrentRegion()?.skip ? (
            <div
              style={{
                position: 'relative',
                width: canvasWidth,
                height: canvasHeight,
                border: '2px solid red',
              }}
            >
              <Close
                style={{
                  position: 'absolute',
                  fontSize: 250,
                  color: 'red',
                  left: 'calc(50% - 124.5px)',
                  top: 'calc(50% - 124.5px)',
                  width: 250,
                  height: 250,
                }}
              />
            </div>
          ) : null}
        </div>
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            style={{ marginTop: '5%', marginBottom: '10%' }}
          >
            Cancel
          </Button>
          {cropType.current === 'number' ? getStep1Instructions() : getStep2Instructions()}
          <Typography align="center" style={{ fontSize: 24, marginBottom: 16, marginTop: '5%' }}>
            CURRENT PAGE:
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              disabled={pageNum.current - 1 <= 0}
              onClick={handlePreviousPage}
              style={{ fontSize: 40, marginRight: 16 }}
            >
              <ArrowBack style={{ fontSize: '1.75rem' }} />
            </Button>
            <Typography align="center" style={{ fontSize: 40, lineHeight: '42px' }}>
              {pageNum.current} / {pages}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={getNextPageDisabled()}
              onClick={handleNextPage}
              style={{ fontSize: 40, marginLeft: 16 }}
            >
              <ArrowForward style={{ fontSize: '1.75rem' }} />
            </Button>
          </div>
          <Typography align="center" style={{ fontSize: 20, marginBottom: 16, marginTop: '5%' }}>
            PAGES IDENTIFIED:
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography align="center" style={{ fontSize: 40, lineHeight: '42px' }}>
              {getNumberOfRegionsIdentified()} / {pages}
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
              <Checkbox
                checked={getCurrentRegion()?.skip ?? false}
                onChange={(e, checked) => toggleSkip(checked)}
                style={{ paddingLeft: 0 }}
              />
              <Typography className={classes.buttonContainerText}>Exclude This Sheet</Typography>
            </div>
            {cropType.current === 'title' ? (
              <Button
                variant="contained"
                color="primary"
                onClick={changeCropType}
                style={{ paddingLeft: 8, paddingRight: 8 }}
              >
                Edit Sheet Number Selection
              </Button>
            ) : null}
            {isChecking || isSubmitting ? (
              <CircularLoader style={{ marginTop: 24 }} />
            ) : (
              <SubmitButton
                variant="contained"
                disabled={!canSubmit()}
                onClick={handleSubmit}
                style={{ marginTop: 32, paddingLeft: 8, paddingRight: 8 }}
              >
                {pageNum.current === pages
                  ? `Submit Drawing ${cropType.current === 'number' ? 'Number' : 'Title'} Locations`
                  : 'Next'}
              </SubmitButton>
            )}
          </div>
          {!canSubmit() ? (
            <Typography style={{ marginBottom: 'auto' }} className={classes.buttonContainerText}>
              You must select a region or exclude this sheet to proceed.
            </Typography>
          ) : (
            <Typography style={{ marginBottom: 'auto' }} className={classes.buttonContainerText}>
              (Any pages without a cropping region will adopt the cropping region of the previous
              page)
            </Typography>
          )}
        </div>
      </div>
    </Dialog>
  );
}
