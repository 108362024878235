import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Hidden, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PrimaryIconText from './PrimaryIconText';
import SecondaryIconText from '../lists/SecondaryDateText';
import PDFIcon from '../icons/PDF-icon';
import { downloadFileById, fileIsPdf, formatDate, openInNewTab } from '../../scripts/utils';
import DownloadIcon from '../icons/Download-icon';
import {
  deleteFile,
  editFileById,
  replaceFileByDocumentIdAndFileId,
} from '../../models/api/filesystem';
import { getBidState } from '../../features/bid/selectors';
import { fetchBid } from '../../features/bid/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Delete, InsertDriveFileOutlined, NoteAdd, Replay } from '@material-ui/icons';
import { FileCategoryType, IFile, WorkflowStatusType } from '../../api-client/autogenerated';
import CircularLoader from '../loader/CircularLoader';
import { CancelButton } from '../custom-components/CustomButtons';
import { reloadDocument } from '../../features/document/actions';
import { getDocumentState } from '../../features/document/selectors';
import { getUserState } from '../../features/user/selectors';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import Typography from '@material-ui/core/Typography';

interface DocumentListProps {
  show?: boolean;
  file: IFile;
  disabled?: boolean;
  canDelete?: boolean;
  handleDelete?: (event: React.MouseEvent, id?: string) => void;
  canConform: () => boolean;
  handleConform: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPadding: {
      padding: 0,
      marginBottom: 24,
    },
    icon: {
      minWidth: 0,
      paddingRight: theme.spacing(2),
      marginLeft: 0,
    },
    button: {
      margin: 1,
    },
    itemContainer: {
      width: '100%',
      display: 'flex',
      position: 'relative',
      boxSizing: 'border-box',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textDecoration: 'none',
    },
  }),
);

export default function DocumentListItem(props: DocumentListProps) {
  const classes = useStyles();
  const { show = true, file, disabled, canDelete, handleDelete, canConform, handleConform } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const document = useSelector(getDocumentState);
  const bid = useSelector(getBidState);

  const [isDownloading, setIsDownloading] = useState(false);
  const [reuploadFileDialogOpen, setReuploadFileDialogOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [reuploadFile, setReuploadFile] = useState<File>();

  const getConformDisabled = () => {
    return !!file.isConformedInDrawings && !!file.isConformedInSpecifications;
  };

  const setSealed = async () => {
    if (file.isSealed === true) {
      await editFileById(file.id, { isSealed: false });
      if (bid) dispatch(fetchBid(bid.id));
    }
  };

  const viewPdf = async () => {
    await setSealed();
    openInNewTab(file.id);
  };

  const downloadPdf = async () => {
    try {
      setIsDownloading(true);
      await setSealed();
      await downloadFileById(file.id, file.name, undefined);
    } finally {
      setIsDownloading(false);
    }
  };

  const shouldAllowReupload = () => {
    if (!document?.workflowStatus) return false;
    return (
      [WorkflowStatusType.SubmittedForReview, WorkflowStatusType.UnderReview].includes(
        document.workflowStatus,
      ) &&
      document.files
        ?.filter((f) => f.category === FileCategoryType.DocumentGeneralContractorApproved)
        .every((f) => !f.isVerified)
    );
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleFileReupload = async () => {
    if (!document || !reuploadFile) return;
    await replaceFileByDocumentIdAndFileId(
      document.id,
      file.id,
      reuploadFile,
      handleUploadProgress,
    );
    dispatch(reloadDocument());
  };

  const handleCancelUpload = async () => {
    if (!document) return;
    await deleteFile(document.id, file.id);
    dispatch(reloadDocument());
  };

  const getButtons = () => {
    if (file.isVerified === false)
      return (
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
          <Button variant="outlined" color="primary" disabled className={classes.button}>
            Upload in progress...
          </Button>
          {(file.creatorUserId === user.id || user.isSiteAdmin) && (
            <>
              {shouldAllowReupload() ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<Replay />}
                  onClick={() => setReuploadFileDialogOpen(true)}
                  className={classes.button}
                  style={{ width: 170 }}
                >
                  Re-Upload
                </Button>
              ) : (
                <CancelButton
                  variant="outlined"
                  onClick={handleCancelUpload}
                  className={classes.button}
                  style={{
                    width: 170,
                    borderColor: '#ED3F26',
                    color: '#ED3F26',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Cancel Upload
                </CancelButton>
              )}
            </>
          )}
        </div>
      );
    return (
      <div style={{ display: 'flex', paddingTop: 5 }}>
        {fileIsPdf(file) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={viewPdf}
            className={classes.button}
            startIcon={<VisibilityIcon style={{ width: 24, height: 24 }} />}
            disabled={disabled}
          >
            View {file.isSealed === true ? ' (Sealed)' : undefined}
            {file.isSealed === false ? ' (Opened)' : undefined}
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={downloadPdf}
          className={classes.button}
          disableElevation
          disabled={disabled}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        >
          {!isDownloading ? (
            <DownloadIcon
              style={{ width: 24, height: 24 }}
              fill={disabled ? 'rgba(0, 0, 0, 0.26)' : '#2C69D6'}
            />
          ) : (
            <CircularLoader size={20} style={{ marginRight: 4 }} />
          )}{' '}
          Download {file.isSealed === true ? ' (Sealed)' : undefined}
          {file.isSealed === false ? ' (Opened)' : undefined}
        </Button>
        {canConform() ? (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<NoteAdd />}
            disabled={getConformDisabled()}
            className={classes.button}
            onClick={handleConform}
          >
            Conform
          </Button>
        ) : null}
        {canDelete && handleDelete && !document?.isHidden ? (
          <Button
            variant="outlined"
            color="inherit"
            className={classes.button}
            onClick={(e) => handleDelete(e, file.id)}
            disableElevation
            style={{
              height: '32px',
              color: '#ED3F26',
              border: '2px solid #ED3F26',
              borderRadius: '4px',
              width: 130,
            }}
          >
            <Delete style={{ marginRight: '6px', width: 24, height: 24 }} /> Delete
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <ListItem
      className={classes.noPadding}
      style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
    >
      {file.category === FileCategoryType.DocumentFinalExecuted && (
        <Typography style={{ fontWeight: 700 }}>Final Executed</Typography>
      )}
      {file.category === FileCategoryType.DocumentFinalDeliverable && (
        <Typography style={{ fontWeight: 700 }}>Final Deliverable</Typography>
      )}
      {file.category === FileCategoryType.DocumentBluebeamStudioLog && (
        <Typography style={{ fontWeight: 700 }}>Bluebeam Studio Activity Log</Typography>
      )}
      <div className={classes.itemContainer}>
        <ListItemIcon className={classes.icon}>
          {file.name.endsWith('.pdf') ? (
            <PDFIcon />
          ) : (
            <InsertDriveFileOutlined htmlColor="#7A797A" />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <PrimaryIconText
              text={file.name}
              user={file.creatorUser?.name}
              company={file.creatorUser?.company?.name}
            />
          }
          secondary={<SecondaryIconText date={formatDate(file.createdOn!)} />}
        />
      </div>
      {show && (
        <>
          <Hidden xsDown>{getButtons()}</Hidden>
          <Hidden smUp>
            <div style={{ display: 'flex' }}>
              {fileIsPdf(file) && (
                <IconButton edge="end" color="primary" onClick={viewPdf}>
                  <VisibilityIcon />
                </IconButton>
              )}
              <IconButton edge="end" color="primary" onClick={downloadPdf}>
                <GetAppRoundedIcon />
              </IconButton>
              {canDelete && handleDelete && (
                <IconButton edge="end" onClick={(e) => handleDelete(e, file.id)}>
                  <Delete htmlColor="#ED3F26" />
                </IconButton>
              )}
            </div>
          </Hidden>
        </>
      )}
      <FileUploadDialog
        open={reuploadFileDialogOpen}
        handleClose={() => setReuploadFileDialogOpen(false)}
        title="Reupload File"
        addFile={(f) => setReuploadFile(f as File)}
        removeFile={() => setReuploadFile(undefined)}
        file={reuploadFile}
        canSubmit={!!reuploadFile}
        buttonType="submit"
        handleSubmit={handleFileReupload}
        uploadProgress={uploadProgress}
        requireFileName={file.name}
        disableComments
        disableDesignUpload
      />
    </ListItem>
  );
}
