import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import DayjsUtils from '@date-io/dayjs';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CancelButton } from '../custom-components/CustomButtons';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { modifyDocumentByIdWithResponse } from '../../models/api/documents';
import { getDocumentState } from '../../features/document/selectors';
import { updateDocument } from '../../features/document/actions';
import CircularLoader from '../loader/CircularLoader';
import { addSnackbar } from '../../features/snackbar/actions';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  finish: () => void;
}

export default function EditDueDateDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, finish } = props;

  const document = useSelector(getDocumentState);
  const dispatch = useDispatch();

  const [dueDate, setDueDate] = useState<Dayjs | null>(dayjs().add(1, 'days'));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changeDueDate = async () => {
    if (!dueDate || !document) return;
    setIsSubmitting(true);
    try {
      const { updatedDocument } = await modifyDocumentByIdWithResponse(document.id, {
        patch: { dueDate: dueDate.toISOString() },
      });
      dispatch(updateDocument({ dueDate: updatedDocument.dueDate }));
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Due date changed successfully!',
          severity: 'success',
        }),
      );
      finish();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <DialogTitle className={classes.titleContainer}>
          <Typography className={classes.title} align="left">
            Edit Due Date
          </Typography>
          <IconButton
            style={{ right: '20px', top: '12px', position: 'absolute' }}
            onClick={handleClose}
            classes={{
              root: classes.rootIconButton,
            }}
          >
            <HighlightOffRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            This document's due date has passed. Before you can assign a consultant for review, you
            must first change the due date.
          </Typography>
          <KeyboardDatePicker
            inputVariant="outlined"
            value={dueDate}
            minDate={dayjs().add(1, 'days')}
            format="MM/DD/YYYY"
            onChange={setDueDate}
            style={{ marginTop: 16 }}
          />
        </DialogContent>

        <DialogActions
          style={{ justifyContent: isSubmitting ? 'center' : 'space-between', padding: 16 }}
        >
          {isSubmitting ? (
            <CircularLoader />
          ) : (
            <>
              <CancelButton onClick={handleClose} />
              <Button variant="contained" color="primary" onClick={changeDueDate}>
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </MuiPickersUtilsProvider>
    </Dialog>
  );
}
