import React from 'react';
import { TreeItem } from '@material-ui/lab';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTreeItem from './DocumentTreeItem';
import { ascendingComparator } from '../document-index/DocumentIndexUtils';
import { DocumentFileTree } from '../../scripts/utils';

type Props = {
  tree: DocumentFileTree;
  handleCheck?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    type: DocumentTemplateType,
  ) => void;
  handleSubCheck: (checked: boolean, type: string, id: string) => void;
};

const useStyles = makeStyles({
  treeItemRoot: {
    '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, &.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  treeItemLabel: {
    whiteSpace: 'nowrap',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  treeItemGroup: {
    marginTop: 4,
  },
});

export default function DocumentTypeTreeItem(props: Props) {
  const classes = useStyles();
  const { tree, handleCheck, handleSubCheck } = props;
  const checked = tree.documents.map((d) => d.checked);

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        marginBottom: 6,
      }}
    >
      {/*<Checkbox*/}
      {/*  checked={!checked.some((c) => !c)}*/}
      {/*  indeterminate={checked.includes(true) && checked.includes(false)}*/}
      {/*  onChange={(event, checked) => handleCheck(event, checked, tree.type)}*/}
      {/*  style={{ position: 'absolute', top: 0, padding: 0, marginTop: -3 }}*/}
      {/*/>*/}
      <TreeItem
        nodeId={tree.type}
        label={tree.type}
        style={{ marginLeft: 2 }}
        classes={{
          root: classes.treeItemRoot,
          label: classes.treeItemLabel,
          group: classes.treeItemGroup,
        }}
      >
        {tree.documents
          .sort((a, b) => (a.isPackage ? -1 : ascendingComparator(a, b, 'title')))
          .map((doc) => {
            return (
              <DocumentTreeItem
                key={doc.id}
                type={tree.type}
                document={doc}
                handleCheck={handleSubCheck}
              />
            );
          })}
      </TreeItem>
    </div>
  );
}
