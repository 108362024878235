import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  AddBoxOutlined,
  AttachFile,
  HighlightOffRounded,
  IndeterminateCheckBoxOutlined,
} from '@material-ui/icons';
import {
  DocumentTemplateType,
  FileCategoryType,
  IFile,
  INumberedDocumentView,
  ProjectPhaseType,
} from '../../api-client/autogenerated';
import { getDocumentsByProjectId } from '../../models/api/project';
import { useSelector } from 'react-redux';
import { getProjectState } from '../../features/project/selectors';
import TreeView from '@material-ui/lab/TreeView';
import { TreeItem } from '@material-ui/lab';
import { getTemplateIds } from '../../models/api/templates';
import { getBidSetupById } from '../../models/api/project-bid-setups';
import CircularLoader from '../loader/CircularLoader';
import DocumentTypeTreeItem from '../add-associations/DocumentTypeTreeItem';
import { DocumentFileTree } from '../../scripts/utils';
import { CancelButton } from '../custom-components/CustomButtons';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px 8px 16px',
    borderBottom: '1px solid #EDECEC',
  },
  title: {
    marginTop: '3px',
    marginLeft: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    textTransform: 'uppercase',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  treeItemRoot: {
    '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, &.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  treeItemLabel: {
    whiteSpace: 'nowrap',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  treeItemGroup: {
    marginTop: 4,
  },
});

const getAllTreeFiles = (trees: DocumentFilesTreeItem[]) => {
  return [
    ...trees.map((t) => t.documents).flat(),
    ...trees
      .map((t) => t.documents)
      .flat()
      .map((d) => (d.files || []).flat())
      .flat(),
  ];
};

export interface ImportedFile {
  id: string;
  name: string;
}

interface DocumentFilesTreeItem extends DocumentFileTree {
  phase: ProjectPhaseType;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  importFiles: (files: ImportedFile[]) => void;
  allowMultiple?: boolean;
}

export default function ImportFromDocumentDialog(props: Props) {
  const classes = useStyles();
  const { open, handleClose, importFiles, allowMultiple } = props;

  const project = useSelector(getProjectState);

  const [isLoading, setIsLoading] = useState(true);
  const [trees, setTrees] = useState<DocumentFilesTreeItem[]>([]);

  const allTreeFiles = useMemo(() => getAllTreeFiles(trees), [trees, open]);
  const selectedFiles = allTreeFiles
    .filter((f) => f.checked)
    .map((f) => ({ id: f.id, name: f.title }));

  const hasConstructionDocuments = trees.some((t) => t.phase === ProjectPhaseType.Construction);
  const hasBiddingDocuments = trees.some((t) => t.phase === ProjectPhaseType.Bidding);

  useEffect(() => {
    if (open && project) {
      setIsLoading(true);
      Promise.all([
        getDocumentsByProjectId(
          project.id,
          ['id', 'documentTemplateId', 'title', 'referenceNumber'],
          [
            DocumentTemplateType.AsiDocuments,
            DocumentTemplateType.Addenda,
            DocumentTemplateType.ConstructionChangeDirectives,
          ],
          true,
        ),
        project.bidSetupId ? getBidSetupById(project.bidSetupId) : null,
      ]).then(([documents, bidSetup]) => createTree(documents, bidSetup?.files || []));
    } else {
      setTrees([]);
    }
  }, [open, project]);

  const createTree = async (
    documents: Pick<
      INumberedDocumentView,
      'id' | 'title' | 'documentTemplateId' | 'referenceNumber' | 'revisionNumber' | 'files'
    >[],
    bidFiles: IFile[],
  ) => {
    const newTree: DocumentFilesTreeItem[] = [];
    const templates = await getTemplateIds();
    const documentItems = documents
      .filter((d) => !!d.files?.length)
      .map((d) => {
        const type = templates[d.documentTemplateId] as DocumentTemplateType;
        const documentNumber = `${d.referenceNumber ? `#${d.referenceNumber}` : ''}${
          d.revisionNumber ? `-R${d.revisionNumber}` : ''
        }`;
        return {
          id: d.id,
          title: documentNumber ? `${documentNumber} - ${d.title}` : `${d.title}`,
          type: getLabel(type),
          checked: false,
          files: d.files
            ?.filter((f) => f.name.endsWith('.pdf'))
            .map((f) => ({ id: f.id, title: f.name, checked: false })),
        };
      });

    const fileItems = bidFiles
      .filter(
        (f) =>
          [FileCategoryType.BidDrawings, FileCategoryType.BidSpecifications].includes(f.category) &&
          f.name.endsWith('.pdf'),
      )
      .map((f) => ({ id: f.id, title: f.name, type: f.category, checked: false }));

    documentItems.forEach((item) => {
      const existingTypeIndex = newTree.findIndex((t) => t.type === item.type);
      if (existingTypeIndex !== -1) {
        newTree[existingTypeIndex].documents.push(item);
      } else {
        newTree.push({
          phase: [DocumentTemplateType.Addenda].includes(item.type as DocumentTemplateType)
            ? ProjectPhaseType.Bidding
            : ProjectPhaseType.Construction,
          type: item.type,
          documents: [item],
        });
      }
    });

    fileItems.forEach((item) => {
      const existingTypeIndex = newTree.findIndex((t) => t.type === item.type);
      if (existingTypeIndex !== -1) {
        newTree[existingTypeIndex].documents.push(item);
      } else {
        newTree.push({
          phase: ProjectPhaseType.Bidding,
          type: getLabel(item.type),
          documents: [item],
        });
      }
    });

    setTrees(newTree);
    setIsLoading(false);
  };

  const getLabel = (label: string) => {
    if (Object.values(DocumentTemplateType).includes(label as DocumentTemplateType)) {
      return label;
    } else {
      return label
        .split('_')
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(' ');
    }
  };

  const handleCheck = (checked: boolean, type: string, id: string) => {
    const newTrees = [...trees];
    const typeTree = newTrees.find((t) => t.type === type);
    if (typeTree) {
      const allTypeFiles = [
        ...typeTree.documents,
        ...typeTree.documents.map((d) => (d.files || []).flat()).flat(),
      ];
      const file = allTypeFiles.find((f) => f.id === id);

      if (file) {
        file.checked = checked;
      }

      if (!allowMultiple) {
        getAllTreeFiles(newTrees)
          .filter((f) => f.id !== id)
          .forEach((f) => (f.checked = false));
      }

      setTrees(newTrees);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      PaperProps={{ style: { minWidth: 540 } }}
    >
      <DialogTitle disableTypography className={classes.titleContainer}>
        <Typography className={classes.title} align="left">
          Browse Bidding or Construction
        </Typography>
        <IconButton
          style={{ right: '20px', top: '12px', position: 'absolute' }}
          onClick={handleClose}
          classes={{
            root: classes.rootIconButton,
          }}
        >
          <HighlightOffRounded />
        </IconButton>
        {!allowMultiple ? (
          <div
            style={{
              width: '100%',
              marginTop: 4,
              marginLeft: 6,
              textTransform: 'none',
              fontSize: 16,
            }}
          >
            You may only select one file.
          </div>
        ) : null}
      </DialogTitle>
      <DialogContent style={{ paddingTop: 12 }}>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <TreeView
            defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
            defaultExpandIcon={<AddBoxOutlined />}
          >
            {project?.bidSetupId && hasBiddingDocuments ? (
              <TreeItem
                nodeId="Bidding"
                label="Bidding"
                classes={{
                  root: classes.treeItemRoot,
                  label: classes.treeItemLabel,
                  group: classes.treeItemGroup,
                }}
                style={{ marginBottom: 6 }}
              >
                {trees
                  .filter((t) => t.phase === ProjectPhaseType.Bidding)
                  .map((t) => (
                    <DocumentTypeTreeItem key={t.type} tree={t} handleSubCheck={handleCheck} />
                  ))}
              </TreeItem>
            ) : null}
            {hasConstructionDocuments ? (
              <TreeItem
                nodeId="Construction"
                label="Construction"
                classes={{
                  root: classes.treeItemRoot,
                  label: classes.treeItemLabel,
                  group: classes.treeItemGroup,
                }}
              >
                {trees
                  .filter((t) => t.phase === ProjectPhaseType.Construction)
                  .map((t) => (
                    <DocumentTypeTreeItem key={t.type} tree={t} handleSubCheck={handleCheck} />
                  ))}
              </TreeItem>
            ) : null}
            {trees.length === 0 ? (
              <Typography>There are no eligible documents with files.</Typography>
            ) : null}
          </TreeView>
        )}
      </DialogContent>

      <DialogActions style={{ justifyContent: 'space-between', padding: 16 }}>
        <CancelButton onClick={handleClose} />
        <Button
          color="primary"
          variant="contained"
          disabled={selectedFiles.length === 0}
          onClick={() => importFiles(selectedFiles)}
          startIcon={<AttachFile style={{ transform: 'scaleY(-1) rotate(45deg)' }} />}
        >
          Attach
        </Button>
      </DialogActions>
    </Dialog>
  );
}
